@import './vars.scss';
.user-info {
    background: $theme-palette-dark-main;
    padding: $theme-spacing-unit 0 $theme-spacing-unit 0;
    min-height: calc(200px + #{$theme-spacing-unit});
    display: flex;
    color: $theme-palette-primary-contrastText;
    @media screen and (max-width: $md) {
        text-align: center;
        h1 {
            font-size: 1.8rem;
        };
    };
    .container {
        display: flex;
        @media screen and (max-width: $md) {
            flex-direction: column;
        };
        .user-info-left {
            display: flex;
            flex: 1 1;
            align-items: center;
            .user-info-avatar, .user-info-content {
                flex: 1 1;
            }
            .user-info-avatar {
                flex: 180px 0;
                @media screen and (max-width: $md) {
                    flex: 120px 0;
                };
            };
            .user-info-content {
                display: flex;
                flex-direction: column;
                @media screen and (max-width: $md) {
                    align-items: flex-start;
                };
                .user-area-button {
                    display: flex;
                    margin-top: $theme-spacing-unit;
                    .btn {
                        margin-right: $theme-spacing-unit;
                        &:last-child {
                            margin-right: 0;
                        };
                    };
                };
            };
        };
        .user-info-right {
            flex: 1 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            @media screen and (max-width: $md) {
                justify-content: center;
            };
            .btn {
                margin-right: $theme-spacing-unit;
                &:last-child {
                    margin-right: 0;
                };
            };
        };
    };
};
