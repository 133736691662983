@import './vars.scss';
.rankitem{
    border: 0;
    display: flex;
    min-height: calc(80px + #{$theme-spacing-unit});
    padding: $theme-spacing-unit;
    width: 100%;
    position: relative;
    .rank-avatar {
        flex: 100px 0;
        align-items: center;
        justify-content: center;
        display: flex;
    }
    .rank-content{
        flex-grow: 1;
        display: flex;
        flex: 1 0;
        flex-direction: column;
        justify-content: center;
        .rank-box {
            display: flex;
            align-items: center;
            &:first-child {
                flex-direction: column;
                align-items: flex-start;
            };
            small {
                @media screen and (max-width: $md) {
                    position: absolute;
                    top: 0;
                    right: 0;
                };
            };
        };
    };
    .rank-rank{
        flex: 250px 0;
        align-items: center;
        justify-content: center;
        display: flex;
    };
};