@import './vars.scss';
.modal-backdrop.show {
  opacity: 0.95;
};
.modal-request {
  max-width: 70%;
  margin: auto;
  padding: 100px 0;
  position: relative;
  @media screen and (max-width: $md) {
    max-width: 100%;
  };
  @media screen and (max-width: $lg) {
    max-width: 90%;
  };

  .modal-content {
      background: transparent;
      color: $theme-palette-primary-contrastText;
      border: 0;
  };
  .modal-header {
      border: 0;
  };
  .modal-body {
      background: $theme-palette-primary-contrastText;
      color: $theme-palette-dark-main;
      border-top-right-radius: $theme-radius;
      border-top-left-radius: $theme-radius;
      padding: $theme-spacing-unit * 4;
  };
  .modal-footer {
      border: 0;
      background: $theme-palette-dark-main;
      color: $theme-palette-primary-contrastText;
      border-bottom-right-radius: $theme-radius;
      border-bottom-left-radius: $theme-radius;
      display:flex;
      flex-direction: column;
      padding: 0;
  };
  .close-modal {
    width: 40px; 
    height: 40px;
    display:flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -50px;
    right: 0;
    button {
      background: transparent;
      border: 0;
      color: $theme-palette-dark-contrastText;
      font-size: 25px;
    };
  };
};

.modal-request-header {
    display: flex;
    align-items: stretch;
    padding: 0;
    margin-bottom: $theme-spacing-unit;
    @media screen and (max-width: $md) {
      flex-direction: column;
    };
    .modal-request-ticket {
        display: flex;
        flex: 1 1;
        @media screen and (max-width: $md) {
          margin-bottom: $theme-spacing-unit * 2;
        };
    };
    .modal-request-info {
        display:flex;
        flex: 1 1;
        @media screen and (max-width: $md) {
          flex-direction: column;
        };
        div {
            flex: 1 1;
            display:flex;
            flex-direction: column;
            display: flex;
            justify-content: center;
            @media screen and (max-width: $md) {
              margin-bottom: $theme-spacing-unit;
            };
            &.modal-request-plus {
                background: $theme-palette-plus-main;
                border-radius: $theme-radius;
                padding: 0 $theme-spacing-unit
            };
        };
    };
    .modal-request-title {
        margin-left: $theme-spacing-unit;
        flex-grow: 1;

        p {
          word-break: break-all;
        }
    };
    .text-tags {
      color: $theme-palette-primary-main;
      font-weight: 700;
    }
};

.modal-request-body {
  font-size: 1.1em;

  .file-wrapper {
    margin-bottom: $theme-spacing-unit*2;
    label {
      text-decoration: underline;
      margin-bottom: 0px;
      &:hover {
        cursor: pointer;
      }
    }
    .file-uploaded {
      font-size: 0.85em;

      span {
        margin-right: $theme-spacing-unit;
      }
      .spacing-left{
        margin-left: $theme-spacing-unit;
        cursor: pointer;
      }
    }
  }

  input[type=file] {
    display: none;
  }

  p {
    margin-bottom: $theme-spacing-unit * 2;
  }

  .form-group {
    position: relative;

    label {
      width: calc(100%-40px);
      width: 95%;
      color: $theme-palette-grey-main;
      margin-bottom: $theme-spacing-unit * 1.75;
    }

    .form-check-label {
      margin-left: $theme-spacing-unit * 3;
      color: $theme-palette-dark-main;
      width: 100%;
    }

    .btn-tooltip {
      height: 40px;
      width: 40px;
      top: 0;
      right: 0;
      position: absolute;
      margin-right: -10px;
    }

    .btn-action {
      font-size: 1em;
      color: $theme-palette-grey-main;

      &:hover {
        color: $theme-palette-primary-main;
      }
    }

    p {
      color: $theme-palette-grey-main;
    }
  }
};

.modal-footer > :not(:first-child) {
  margin: 0;
};


.modal-notify-request {
  .modal-content {
    background: transparent;
    padding-top:100px;
    .modal-footer, .modal-body {
      border: 0;
      justify-content: center;
      display:flex;
    };

    .modal-body {
      flex-direction: column;
      align-items: center;
      background: $theme-palette-dark-main;
      color: $theme-palette-dark-contrastText;
      padding-bottom: 20px;
      border-top-right-radius: 0.3rem; 
      border-top-left-radius: 0.3rem; 
      .avatar {
          margin-bottom: 20px;
          margin-top: -50px;
      }
      .fields{
        padding-top: $theme-spacing-unit;
        width: 90%;
        .invalid-feedback {
          display: block;
          width: 100%;
          margin-top: 0.25rem;
          font-size: 80%;
          color: #dc3545;
        }
      }
      .request-last-wrapper {
        height: 80px;
        margin-top: $theme-spacing-unit;
      };

      .request-last-up-down {
        height: auto;
        margin-top: $theme-spacing-unit;
        .item{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
      };

      .is-invalid{
        border-color: #dc3545;
      }

      p {
        margin-top: $theme-spacing-unit*2;
        text-align: center !important;
        font-size: 1.1em;
      }
    };
    .no-padding-botton{
      padding-bottom: 0px;
    }
    .modal-footer {
      background: $theme-palette-dark-main;
      padding: 0;
      .new-footer {
        width: 100%;
        display:flex;
        padding: 16px;
        button {
          flex: 1 1;
          margin: 0;
          height: 70px;
          &:first-child {
            margin-right: 8px;
          }
          &:last-child {
            margin-left: 8px;
            background: transparent;
            border-color:$theme-palette-dark-light;
            color: $theme-palette-grey-muted;
            &:hover {
              border-color:$theme-palette-grey-muted;
              color: $theme-palette-dark-contrastText;
            }
          }
        }
      }
    }
  };
};