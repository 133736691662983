@import './vars.scss';
.status {
    display: flex;
    min-height: 140px;
    height: auto;
    @media screen and (max-width: $md) {
        height: auto;
        flex-direction: column;
        padding: 20px 0;
    };
    .status-area-left {
        flex: 1 1;
        display: flex;
        justify-content: center;
        flex-direction: column;
    };
    .status-area-right {
        flex: 1 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @media screen and (max-width: $md) {
            justify-content: flex-start;
        };
    };
    .status-box-request {
        display:flex;
        margin: $theme-spacing-unit * 2 0 $theme-spacing-unit 0;
        align-items: center;
        div {
            .status-tag {
                margin-right: 20px;
            };
        };
    };
    .status-tag {
        border-radius: $theme-radius;
        padding: $theme-spacing-unit * 2 $theme-spacing-unit * 6 $theme-spacing-unit * 2 $theme-spacing-unit * 6 
    };
};