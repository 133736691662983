@import './vars.scss';
.TabComments-container{
    border: 0;
    display: flex;
    flex-direction: column;
    padding: $theme-spacing-unit;
    background: $theme-palette-primary-contrastText;
    border-bottom: 3px solid $theme-palette-border-gray;
    border-right: 1px solid $theme-palette-border-gray;
    .title-chat-area {
        height: auto;
        display: flex;
        align-items: center;
    };
    .notify-buttonarea{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: $theme-spacing-unit*2;
    };
}

.TabComments-list {
    overflow-y: auto;
    max-height: 400px;
}
