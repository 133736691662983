@import './vars.scss';
.profile-main{
    display: block;
    height: auto;
    margin-bottom: $theme-spacing-unit;
    .profile-rank {
        flex: 1 1;
        align-items: center;
        justify-content: center;
        display: flex;
        height: 100px;
        margin-bottom: $theme-spacing-unit * 4;
        margin-top: $theme-spacing-unit * 4;
        .profile-rank-right{
            display: flex;
            flex: 50% 1;
            align-items: center;
            justify-content: center;
            small{
                width: 35%;
                margin-left: $theme-spacing-unit * 2;
            }
            svg{
                color: $theme-palette-primary-main;
                width: 30px!important;
                height: 100%!important;
                margin: $theme-spacing-unit;
            }
        }
        .profile-rank-left{
            display: flex;
            flex: 50% 1;
            align-items: center;
            justify-content: center;
            small{
                width: 35%;
                margin-left: $theme-spacing-unit * 2;
            }
        }
    }
    .profile-rank-list{
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: $theme-spacing-unit;
        align-items: center;
        border: 1px $theme-palette-grey-muted solid; 
        border-bottom: 0;
    }
    .profile-rank-footer{
        border: 1px $theme-palette-grey-muted solid;
        border-top: 0;
        background-color: $theme-palette-grey-medium;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: $theme-spacing-unit * 3;
        cursor: pointer;
    }
    .off{
        padding: 0;
    }
}