@import "./vars.scss";

.notify {
  background: $theme-palette-notify-info-main;
  border: 1px solid $theme-palette-grey-muted;
  min-height: calc(40px + #{$theme-spacing-unit});
  padding: $theme-spacing-unit;
  display: flex;
  color: $theme-palette-alert-info-contrastText;
  flex-direction: column;
  word-wrap: break-word;
  margin-top: $theme-spacing-unit;
} 
.notify-main{
  padding: $theme-spacing-unit;
  border-bottom: 3px solid $theme-palette-border-gray;
  border-right: 1px solid $theme-palette-border-gray;
  background: $theme-palette-primary-contrastText;
  margin-bottom: $theme-spacing-unit;
  p{
    font-size: 0.85em;
  }
  .notify-buttonarea{
    display: flex;
    align-items: center;
    justify-content: center;
  };
};

.notify-sticky-wrapper {
    display: flex;
    bottom: $theme-spacing-unit * 2;
    right: $theme-spacing-unit * 2;
    position: fixed;
    background: $theme-palette-primary-contrastText;
    color: $theme-palette-alert-info-contrastText;
    width: 350px;
    border-radius: $theme-radius;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);
    .notify-avatar {
      flex: 50px 0;
      padding: $theme-spacing-unit;
    };
    .notify-body {
      flex: 1 1;
      padding: 0px $theme-spacing-unit;
      display:flex;
      flex-direction: column;
      justify-content: center;

    };
    .notify-action {
      flex: auto 0;
      display: flex;
      align-items: center;
      border-left: 1px solid $theme-palette-grey-muted;
      button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        background: $theme-palette-primary-main;
        height: 100%;
        border: 0;
        &:hover {
          background: $theme-palette-primary-dark;
          color: $theme-palette-primary-contrastText;
        };
      };
    };
};
