@import './vars.scss';
.alert {
    min-height: 40px;
    height: auto;
    padding: 0 $theme-radius ($theme-radius*4) ($theme-radius*3);
    display:flex;
    color: $theme-palette-alert-info-contrastText;
    flex-direction: column;
    word-wrap: break-word;
    border: 1px solid $theme-palette-alert-info-main;
    margin: $theme-spacing-unit 0 0 0;
    cursor: pointer;
    width: 100%;
    &.alert-danger {
        background: $theme-palette-alert-danger-main;
        color: $theme-palette-alert-danger-contrastText;
    };
    &.alert-info {
        background: $theme-palette-alert-info-main;
        color: $theme-palette-alert-info-contrastText;
        border: 1px solid $theme-palette-alert-info-dark;
    };
    .alert-header {
        flex: 20px 0;
        display:flex;
        .circle-info {
            color: black;
        };
        .alert-header-id{
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            flex: 50px 1;
        }
        .alert-header-mix{
            display: flex;
            align-items: flex-start;
            flex: 130px 1;
            justify-content: flex-end;
            color: $theme-palette-grey-muted;
        }
        .alert-header-mix-danger{
            display: flex;
            align-items: flex-start;
            flex: 130px 1;
            justify-content: flex-end;
            color: $theme-palette-primary-contrastText;
            svg{
                color: $theme-palette-primary-dark;
            }
        }
    };
    .alert-content {
        flex: auto 1;
        display: flex;
        div {
            flex: 1 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            &:last-child {
                align-items: flex-end;
            };
        };
        strong{
            font-size: 1em;
        }
    };
    p{
        padding-right: $theme-radius;
    }
};
.alert-main{
    padding: $theme-spacing-unit;
    margin-bottom: $theme-spacing-unit*3;
    margin-top: 0;
    border: 1px solid $theme-palette-grey-light;
    background: $theme-palette-primary-contrastText;
    &.no-alert {
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
            color: $theme-palette-grey-main;
        }
    };
    p{
        font-size: 0.85em;
    }
}

.alert-custom {
    flex-direction: row;
    padding: $theme-spacing-unit;
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100% - 30px);
    margin-bottom: $theme-spacing-unit;
    cursor: default;
    .alert-icon {
        flex: 40px 0;
        display: flex;
        justify-content: center;
        align-items: center;
    };
    .alert-message {
        flex: 1 1;
        display: flex;
        align-items: center;
        justify-content: center;
    };
};

.alert-dismissible .close {
    padding: 0.3rem 0.5rem;
}
.alert-widget {
    font-size: 1rem;
}
.alert-warning {
    border-color: #ffeeba!important;
}

.alert-form {
    min-height: 5px!important;   
    padding: 6px 12px 6px 12px!important;
    p {
        margin: 0px!important;
    }
}