@import './vars.scss';
.maar-item{
    display: flex;
    min-height: calc(40px + #{$theme-spacing-unit});
    width: 100%;
    flex-direction: column;
    .maar-text{
        flex: 1 1;
        display: flex;
        padding: $theme-spacing-unit;
        align-items: center;
        justify-content: center;
    }
    label{
        padding-left: $theme-spacing-unit + 2;
        padding-right: $theme-spacing-unit + 2;
        border-radius: 0;
    }
    label::before{
        top: 0;
        width: 1.40rem;
        height: 1.40rem;
        border: 1px $theme-palette-grey-muted solid;
    }
    label::after{
        top: 0;
        width: 1.40rem;
        height: 1.40rem;
        border: 1px $theme-palette-grey-muted solid;
    }
    .maar-input {
        flex: 2 1;
        z-index: 0;
        .form-group {
            height: 100%;
            .input-group {
                height: 100%;
                display:flex;
                align-items: stretch;
                .form-control {
                    height: 100%;
                    height: calc(100% - 0);
                    flex: 1 1;
                    padding: 0px $theme-spacing-unit * 2;
                };
                .input-group-prepend {
                    height: 100%;
                    .btn {
                        height: calc(100% + 2px);
                    };
                };
            };
        };
        .form-control{
            border: 1px $theme-palette-grey-muted solid;
            box-sizing: content-box;
            color: $theme-palette-grey-main;
        }
        button{
            border: 1px $theme-palette-grey-muted solid;
            height: 52px;
            z-index: 0;
        }
        svg{
            color: $theme-palette-grey-muted;
            width: 20px!important;
            height: 100%!important;
            margin: 0;
        }
        input{
            border-radius: 0;
        }
    }
    &.search-without-params {
        .maar-search{
            @media screen and (max-width: $md) {
                height: 60px!important;
            };
        };
    };
    .maar-search{
        display: flex;
        align-items: stretch;
        margin-bottom: $theme-spacing-unit;
        height: 40px;
        @media screen and (max-width: $md) {
            display: block!important;
            height: 160px!important;
            .maar-select, .maar-checks, .maar-input {
                width: 100%!important;
                height: 50px!important;
                display: block!important;
            };
        };
        .maar-select{
            margin-right: $theme-spacing-unit;
            flex: 1 1;
            @media screen and (max-width: $md) {
                margin-bottom: $theme-spacing-unit;
            }
            .form-group {
                height: 100%;
                margin-bottom: 0;
                .form-control {
                    height: 100%;
                };
            };
        };
        .maar-checks{
            flex: 1 1;
            display:flex;
        };
    };
    .maar-list{
        .pagination-wrapper {
            overflow-x:auto;
        }
        .pagination {
            justify-content: center;
            margin:30px;
        }
        .data-slice {
            margin-top: 15px;
        }
    }
    .alert_message{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: $theme-spacing-unit * 2;
    }
    .alert-custom {
      margin-left: 0;
      margin-right: 0;
      margin-top: 8px;
      width: inherit;
    }

    .maar-alerts div:first-child {
      margin-top: 0;
    }

    .maar-alerts div:last-child {
      margin-bottom: 8px;
    }
}
