@import './vars.scss';
.bg-item{
    border-bottom: 1px $theme-palette-grey-muted solid; 
    display: flex;
    width: 100%;
    &:first-child{
        border-top: 1px $theme-palette-grey-muted solid;
    };
    &:hover {
        background: $theme-palette-grey-light;
        cursor:pointer;
    };
    .bg-text{
        flex: 1 1;
        display: flex;
        justify-content: flex-start;
        padding: $theme-spacing-unit * 2;
        align-items: center;
        align-content: center;
        label{
            padding-left: $theme-spacing-unit + 2;
        }
        label::before{
            top: 0;
            width: 1.40rem;
            height: 1.40rem;
            border: 1px $theme-palette-grey-muted solid;
            border-radius: 0.25rem;
        }
        label::after{
            top: 0;
            width: 1.40rem;
            height: 1.40rem;
            border: 1px $theme-palette-grey-muted solid;
            border-radius: 0.25rem;
        }
    }
}