@import './vars.scss';
.avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 50%;
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    };
    &.xs {
        width: 25px;
        height: 25px;
    };
    &.sm {
        width: 45px;
        height: 45px;
    };
    &.md{
        width: 65px;
        height: 65px;
    };
    &.lg{
        width: 85px;
        height: 85px;
    };
    &.xlg{
        width: 140px;
        height: 140px;
        @media screen and (max-width: $md) {
            width: 90px;
            height: 90px;
        };
    };
};

.avatar-list {
    display:flex;
    padding: $theme-spacing-unit;
    .avatar-list-item {
        margin-left: -10px;
        height: 75px;
        width: 75px;
        border-radius: 50%;
        border: 10px solid #3F4448;
        .avatar {
            margin-left: -5px;
            margin-top: -5px;
        };
    };
};
