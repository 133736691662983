@import './vars.scss';
.bbr-item{
    border: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $theme-palette-grey-light;
    padding: $theme-spacing-unit;
    min-height: 90px;
    margin-top: $theme-spacing-unit * 3;
    .bbr-text{
        flex: 1 1;
        padding: $theme-spacing-unit;
        margin-left: $theme-spacing-unit * 2;
    }
    .bbr-button{
        flex: 110px 0;
    }
}