@import "./vars.scss";
.responsive-border-card-task {
  border-left: 1px solid #dee2e6;
  @media screen and (max-width: $md) {
    border-left: 0;
    margin-top: 16px;
    padding-top:16px;
  }
}
.text-tags {
  color: $theme-palette-primary-main;
};
.heading-category {
  color: rgba(0, 0, 0, 0.87);
  p {
    color: $theme-palette-grey-main;
  };
  margin-bottom: $theme-spacing-unit * 2;
};
