@import './vars.scss';
.ar-item{
    border: 1px $theme-palette-grey-muted solid;
    display: flex;
    min-height: calc(80px + #{$theme-spacing-unit});
    border-left: $theme-spacing-unit solid transparent;
    margin-bottom: $theme-spacing-unit;
    position: relative;
    &.normal {
        border-left-color: $theme-palette-bg-assigned;
    }
    &.plus {
        border-left-color: $theme-palette-bg-avaliable;
    }
    .ar-avatar {
        flex: 70px 0;
        align-items: center;
        justify-content: center;
        display: flex;
        cursor: pointer;
    }
    .ar-content{
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: $theme-spacing-unit * 2 $theme-spacing-unit;
        align-items: center;
        &.ar-content-mobile {
            flex-direction: column;
            align-items: flex-start;
            .ar-content-mobile-date, .ar-content-mobile-estimated {
                position: absolute;
                right: $theme-spacing-unit;
            };
            .ar-content-mobile-date {
                top: 0;
            };
            .ar-content-mobile-estimated {
                bottom: 0;
            };
        };
        & div {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            flex: 1 1;
        };
    };
    .ar-buttonarea{
        flex: 200px 0;
        align-items: center;
        justify-content: flex-end;
        display: flex;
        padding-right: $theme-spacing-unit * 2;
        &.multiple-button {
            padding: $theme-spacing-unit;
            flex-direction: column;
        }
        @media screen and (max-width: $md) {
            flex: auto 0;
        };
    };
    .ari-lastarea{
        flex: 200px 0;
        display: flex;
    }
    .ar-actionarea{
        flex: 200px 0;
        align-items: center;
        justify-content: flex-end;
        display: flex;
        padding-right: $theme-spacing-unit * 2;
        @media screen and (max-width: $md) {
            justify-content: flex-end;
        };
        .widget-rank {
          height: 46px;
          .rank span svg {
              width: 30px!important;
          }
        }
    }
    .ari-info{
        display: flex;
        flex-direction: column;
        width: 170px;
        & div:first-child {
            flex: 30px 0;
            flex-direction: column;
            justify-content: left;
        }
        & div {
            flex: 1 1;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .widget-rank .rank span svg {
            width: 30px!important;
        }
    }
    .ari-links{
        width: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        svg{
            color: $theme-palette-grey-muted;
            width: 20px!important;
            height: 100%!important;
            margin: 0;
            padding-bottom: $theme-spacing-unit;
            cursor: pointer;
        }
    }
    @media screen and (max-width: $md) {
        flex-wrap: wrap;
        .ar-content {
            flex: calc(100% - 70px)
        };
        .ari-lastarea{
            flex: 100%;
            margin-bottom: 20px;
            .ar-buttonarea, .ar-actionarea {
                flex: 1 0;
                justify-content: center;
                padding: 0 $theme-spacing-unit;
                h6 {
                    font-weight:800;
                    font-size: 1.2rem;
                }

            }
            button.btn {
                width: 100%;
                &:nth-child(1){
                    order: 1;
                } 
                &:nth-child(2){
                    order: 2
                } 
            }
        }
    };
}
