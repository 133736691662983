@import './vars.scss';
.cbitem{
    display: flex;
    flex-direction: column;
    margin: $theme-spacing-unit * 5;
    .cbinput {
        height: 100%;
        width: 100%;
        box-sizing: content-box;
        .form-control{
            border: 0;   
            color: $theme-palette-grey-main;         
        }
        button{
            border: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: 1px $theme-palette-grey-muted solid;
        }
        .input-group{
            align-items: center;
            border: 1px $theme-palette-grey-muted solid;
            border-radius: 4px;
            display: flex;
        }
    }
 
}