@import './vars.scss';
.sbitem{
    display: flex;
    flex-direction: column;
    border-left: 1px $theme-palette-grey-muted solid;
    border-top: 1px $theme-palette-grey-muted solid;
    border-right: 1px $theme-palette-grey-muted solid;
    background: $theme-palette-primary-contrastText;
    .sbinput {
        height: 100%;
        width: 100%;
        border: 0;
        box-sizing: content-box;
        .form-control, .input-group-prepend {
            height: 100%;
        }
        .form-control{
            border: 0;
            color: $theme-palette-grey-main;
        };
        button{
            background-color: transparent;
            border: 0;
            height: 100%;
        };
        svg{
            color: $theme-palette-grey-muted;
            width: 20px!important;
            height: 100%!important;
            margin: 0;
        };
        .input-group{
            align-items: center;
            height: 60px;
        }
    }
    .sblist {
        flex: 200px 0;
        height: 200px;
        overflow-y: auto;
    }
    .sbnoresults{
        display: flex;
        flex-direction: column;
        flex: 200px 0;
        border: 1px $theme-palette-grey-muted solid;
        height: 200px;
        align-items: center;
        justify-content: center;
        button{
            margin-top: $theme-spacing-unit * 2;
        }
    }
    .sbbuttonsarea{
        padding: ($theme-spacing-unit*2);
    }
    .sblistbuttons{
        margin: $theme-spacing-unit;
        button{
            margin-left: $theme-spacing-unit;
        }
        .biitem {
          margin-left: 0px;
          margin-right: $theme-spacing-unit;
          margin-bottom: $theme-spacing-unit;
          padding: $theme-spacing-unit / 2;
          border-radius: 50px;
          display: flex;
          background: $theme-palette-grey-muted;
          overflow: hidden;
            .biitem-info {
                color: $theme-palette-dark-light;
                padding: 0 $theme-spacing-unit / 2;
            };
            .biitem-icon {
                background: $theme-palette-grey-main;
                color: $theme-palette-grey-muted;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                &:hover {
                    background: $theme-palette-dark-light;
                    color: $theme-palette-primary-contrastText;
                };
            };
        };
    };
};

.text-result {
  margin-bottom: $theme-spacing-unit;
}
