@import './vars.scss';
.steps-hero-wrapper {
    width: 100%;
    display: flex;
    .steps-items {
        flex: 1 1;
        display: flex;
        margin-right: $theme-spacing-unit;
        &:last-child {
            margin-right: 0;
        };
        .steps-item {
            flex: 1 1;
            padding: $theme-spacing-unit;
            &:first-child {
                flex: 60px 0;
                padding: 0;
                display: flex;
                justify-content: center;
                align-items: flex-start;
            };
            .steps-icon {
                height: auto;
                width: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 900;
                font-size: 1.5rem;
                overflow: hidden;
                img {
                    width: 80px;
                    height: auto;
                }
            };
        };
    };
};