.input-tooltip {
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.25);
  border-radius: $theme-radius;
  width: 440px;
  height: 120px;
  background: $theme-palette-dark-contrastText;
  z-index: 1060;

  .tooltip-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .tooltip-close {
    position: absolute;
    top: $theme-spacing-unit;
    right: $theme-spacing-unit;
    width: 40px;
    height: 40px;
    padding: 0px!important;
    margin: 0px!important;
  }

  .tooltip-content {
    position: absolute;
    left: 25%;
    right: $theme-spacing-unit*4;
    top: 12%;
    text-align: justify;

    h4 {
      text-align: left;
      font-size: 1.28em;
      font-weight: 700;
      margin-bottom: $theme-spacing-unit*2;
    }

    small {
      display: block;
      margin-bottom: $theme-spacing-unit;
    }

    .widget-rank {
      padding: $theme-spacing-unit 0;
      .rank span {
          height: 26px;
      }
    }
  }

  .tooltip-footer {
    position: absolute;
    bottom: $theme-spacing-unit;
    right: $theme-spacing-unit;

    a {
      font-size: 0.85em;
    }
  }

  .tooltip-logo {
    top: $theme-spacing-unit;
    right: $theme-spacing-unit;
    position: absolute;
    left: 5%;
    right: 80%;
    top: 15%;
  }

  .tooltip-inner {
    color: $theme-palette-dark-main;
    text-align: left;
  }
}
