@import './vars.scss';

.modal-login {
    background: $theme-palette-primary-contrastText;
    display:flex;
    width: 100%;
    margin: 0!important;
    min-height: 700px;
    color: $theme-palette-dark-main;
    @media screen and (max-width: $lg) {
        .image-login {
            display: none
        };
        .form-login {
            padding: 32px
        }
    };
    .form-login {
        flex: 1 1;
    };
    .image-login {
        flex: 1 1;
        position: relative;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        };
        span {
            position: absolute;
            bottom: 10%;
            background: $theme-palette-primary-main;
            padding: $theme-spacing-unit * 4;
            width: 90%;
            left: 0;
            color: $theme-palette-primary-contrastText;
        };
    };
};