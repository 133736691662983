@import './vars.scss';

.request-doc-wrapper {
    display:flex;
    flex-wrap: wrap;
    .request-last-wrapper {
        flex: 100% 0;
        height: 100px;
        display: flex;
        padding: $theme-spacing-unit * 2;
        border: 1px solid $theme-palette-grey-light;
        margin-bottom: $theme-spacing-unit;
        background: $theme-palette-primary-contrastText;
        .item {
            flex: 1 1;
            display: flex;
            align-items: center;
            &:first-child {
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
            };
            &:last-child {
                flex-grow: 1 1;
                display:flex;
                justify-content: flex-end;
            };
            .in-revision{
                background: $theme-palette-notify-info-highContrast;
                width: 100%;
                height: 100%;
                border: 1px solid $theme-palette-grey-light;
                padding: $theme-spacing-unit;
                margin-top: $theme-spacing-unit*2;
            }
        }
    };
    .no-bottom{
        margin-bottom: 0;
    }
    .disableddiv {
        pointer-events: none;
        opacity: 0.2;
    }
    .form-inline{
        width: 100%;
    }
    .withRevision{
        height: 100%;
        small{
            word-wrap: break-word;
            text-align: justify;
            text-overflow: ellipsis;
        }
    }
    .revision{
        height: auto;
        flex-direction: column;
        .item{
            height: 90%;
            textarea{
                height: 150px;
                width: 100%;
            }
        }
        .row{
            margin-top: $theme-spacing-unit * 2;
        }
    }
    .request-last-up-down {
        border: 1px solid $theme-palette-grey-light;
        flex: 1 0;
        display:flex;
        padding: $theme-spacing-unit * 2;
        background: $theme-palette-primary-contrastText;
        margin-right: $theme-spacing-unit!important;
        @media screen and (max-width: $md) {
            flex: 100% 0;
            margin-right: 0!important;
        };
        &:last-child {
            margin-right: 0;
            .item {
                svg {
                    color: $theme-palette-primary-main
                };
            };
        };
        .item {
            flex: 1 1;
            &:last-child {
                flex: 80px 0;
                display:flex;
                justify-content: center;
                align-items: center;
                .icon {
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 4rem;
                    a {
                      &:hover {
                        cursor: pointer;
                      }
                    }
                    .spinner-border {
                      width: 4rem;
                      height: 4rem;
                    }
                };
            };
        };
    };

    .alert-custom {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
};
.bbr-wrapper {
    margin-bottom: $theme-spacing-unit;
};
.request-doc-widget  {
    .request-last-up-down {
        margin-bottom: $theme-spacing-unit * 3;   
        margin-right: 0px;
    }
    .request-last-wrapper {
        margin-bottom: $theme-spacing-unit * 3;   
        margin-right: 0px;
        height: 100%;
        flex-direction: column;
        border-bottom: 3px solid $theme-palette-border-gray;
        border-right: 1px solid $theme-palette-border-gray;

        .item:last-child {
            justify-content: center;

            button {
                width: 100%;
            }
        }

        .in-revision {
            margin-bottom: $theme-spacing-unit * 2;   
        }
        
    }
    
    .bbr-item {
        margin-top: 0px;
        flex-direction: row;
        border-bottom: 3px solid $theme-palette-border-gray;
        border-right: 1px solid $theme-palette-border-gray;
        
        .bbr-text {
            padding: 0px; 
            margin-left: 0px;
        }

        .bbr-button {
            flex: auto 0;
        }
    }

    .revision-open {
        margin-bottom: 0px;
    }

    .alert-custom {
        margin-bottom: $theme-spacing-unit * 3;   
        margin-top: 0px;
    }

    .revision-action {
        justify-content: center!important;
    }
}

.custom-actions-wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
    background:$theme-palette-primary-contrastText;
    border-right: 1px $theme-palette-grey-muted solid;
    border-left: 1px $theme-palette-grey-muted solid;
    border-bottom: 1px $theme-palette-grey-muted solid;
    margin-bottom: $theme-spacing-unit;
}