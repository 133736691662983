@import './vars.scss';

.disponibility-wrapper {
    width: 100%;
    height: auto;
    display:block;
    border: 1px solid $theme-palette-border-gray;
};

.schedule-wrapper {
    display:block;
    width: 100%;
    height: auto;
};
.schedule-title {
    display:block;
    width: 100%;
    height: auto;
    background: $theme-palette-grey-medium;
    padding: $theme-spacing-unit * 2;
};
.schedule-content {
    display:flex;
    padding: $theme-spacing-unit * 2;
    .schedule-content-area {
        flex: 1 1;
        .schedule-location {
            padding: $theme-spacing-unit * 2;
            border: 1px solid $theme-palette-grey-medium;
            border-radius: 4px;
            background: $theme-palette-grey-medium;
            small {
                display:block;
            }
        }
    };
};
.disponibility-action {
    display: flex;
    padding: $theme-spacing-unit * 2;
    justify-content: flex-end;
};
.assistance-wrapper{
    display:block;
    width: 100%;
    height: auto;
    .assistance-header {
        display:flex;
        padding: $theme-spacing-unit * 2;
        background: $theme-palette-grey-muted;
        div {
            flex: 2 1;
            display: flex;
            align-items: center;
            &:last-child {
                flex: 1 1;
                justify-content: flex-end;
            };
        };
    };
    .assistance-content {
        display:block;
        width: 100%;
        height: auto;
    };
};

.assistance-wrapper-email {
    display:block;
    width: 100%;
    height: auto;
    padding: $theme-spacing-unit * 2;
    .assistance-title {
        display:block;
        width: 100%;
        height: auto;
        small {
            display:block;
        };
    };
    .assistance-content {
        display:block;
        width: 100%;
        height: auto;
    };
};
.simple-maps-wrapper{
    width: 96%;
    height: 300px;
}