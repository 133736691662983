@import './vars.scss';

.form-control {
    border-radius: $theme-radius;
};

.buttons-form-action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 150px;
    button {
        margin-right: $theme-spacing-unit;
        &:last-child {
            margin-right: 0;
        };
    };
};

.myaccount-wrapper {
    border: 1px solid $theme-palette-grey-light;
    .form-group {
        padding: $theme-spacing-unit * 4 $theme-spacing-unit * 2;
        border-bottom: 1px solid $theme-palette-grey-light;
        margin-bottom: 0;
        .form-control {
            color: $theme-palette-black
        };
        /*label {
            color: $theme-palette-grey-main
        };*/
        &:last-child {
            border: 0;
        };
    };
    .form-toggle-options {
        border: 0;
        padding: $theme-spacing-unit * 2 $theme-spacing-unit * 2 0 $theme-spacing-unit * 2;
        .flex-right {
            display: flex;
            align-items: center;
            label {
                padding-top: 3px;
                color: $theme-palette-black;
            };
        };
    };
};

.form-group-center {
  align-items: center;
}
