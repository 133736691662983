@import './vars.scss';
.btn {
    border-radius:$theme-radius;
    display:flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-size: 14px;
    padding: 0 $theme-spacing-unit * 4 0 $theme-spacing-unit * 4;
    &:focus {
      box-shadow: none!important;
    };
    .spinner-border {
        flex: 25px 0!important;
        height: 25px;
        margin-left: $theme-spacing-unit;
    };
    &.btn-sm {
        height: 30px;
    };
    &.btn-lg {
        height: 56px;
    };
};

.btn-success {
    background: $theme-palette-btn-success-main;
    border-color: $theme-palette-btn-success-main;
    &:hover {
        background: $theme-palette-btn-success-dark;
        border-color: $theme-palette-btn-success-dark;
    };
};

.btn-danger {
    background: $theme-palette-btn-danger-main;
    border-color: $theme-palette-btn-danger-main;
    &:hover {
        background: $theme-palette-btn-danger-dark;
        border-color: $theme-palette-btn-danger-dark;
    };
};
.btn-square {
  width: 36px;
  height: 36px;
  border-radius: $theme-radius;
  padding: 0;
}
.btn-linkedin {
    background: transparent;
    display: flex;
    align-items: center;
    color: $theme-palette-dark-main;
    svg {
        color:#0077B5;
        font-size: 36px;
    };
};

.btn-secondary {
    background: $theme-palette-dark-main;
    padding-top: $theme-spacing-unit;
    padding-bottom: $theme-spacing-unit;
    display: flex;
    align-items: center;
    svg {
        margin-left: $theme-spacing-unit;
        color:#0077B5;
        font-size: 40px;
    };
};

.action-control {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
  padding: 10px 0;
  .btn-action {
    padding: 0.25rem 0.5rem!important;
    margin-right: $theme-spacing-unit;
    width: 45px;
    &:hover {
      color: $theme-palette-primary-main;
    };
    &:last-child {
      margin-right: 0px;
    };
    svg {
      width: 100%;
    }
    .loading-component .spinner-border {
      margin-left: 0;
    }
  };
};

.link {
    text-align:left;
    padding: 0;
    svg {
        margin-left: $theme-spacing-unit;
    };
};
.text-link {
  color: $theme-palette-dark-main;
  text-decoration: underline;
  &:hover {
    color:#000;
  }
}

.break-word {
  word-break: break-word;
}

.btn-action-button {
  padding: 5px 10px;
  margin-left: 8px;
  span {
    display: flex;
    small {
      margin-right: 8px;
    }
    svg {
      margin-left: 8px;
    };
  };
  &.btn-action-success {
    background: #e5f7e8;
    color: #43ba52;
  };
  &.btn-action-cancel {
    background: #fbdbcf;
    color: #bd2d12;
  };
  &.btn-action-edit {
    background: #fbfae6;
    color: #aa7d16;
  };
  &.btn-action-comment {
    background: #daeff8;
    color: #3190af;
  };
};
