$theme-spacing-unit: 8px;
$theme-radius: 4px;
$theme-transition: .2s;
$theme-margin-wrapper: 50px;

$theme-palette-primary-main: #F25037;
$theme-palette-primary-dark: #DF432B;
$theme-palette-primary-contrastText: #fff;
$theme-palette-danger-contrastText: #cac7c7;

$theme-palette-grey-light: #e5e5e5;
$theme-palette-grey-medium:#F8F8F9;
$theme-palette-grey-muted:#D0D0D3;
$theme-palette-grey-main: #637282;
$theme-palette-dark-light: #3F4448;
$theme-palette-dark-main: #24292E;
$theme-palette-dark-contrastText: #ffffff;

//Alerts
//$theme-palette-alert-info-main: #F1F8FF;
$theme-palette-alert-info-dark: #E1E4E8;
$theme-palette-alert-info-contrastText: #24292E;

$theme-palette-alert-danger-main: #F25037;
$theme-palette-alert-danger-dark: #DF432B;
$theme-palette-alert-danger-contrastText: #fff;

$theme-palette-alert-info-main: #FFFFFF;
$theme-palette-alert-info-dark:#E5E5E5;
$theme-palette-alert-info-contrastText: #24292E;

$theme-palette-bg-avaliable: #89E051;
$theme-palette-bg-assigned: #AD00FF;

//Notify
$theme-palette-notify-info-main: #F1F8FF;
$theme-palette-notify-info-highContrast: #fffff1; 

//Background: 
$theme-palette-bg-gray: #eeeeee;
$theme-palette-border-gray: #e5e5e5;
$theme-palette-bg-dark-main: #24292E;
$theme-palette-bg-dark-contrastText: #ffffff;
$theme-palette-bg-success-main: #89E051;
$theme-palette-bg-success-contrastText: #ffffff;

//Buttons
$theme-palette-btn-success-main: #89E051;
$theme-palette-btn-success-dark: #7dd246;

$theme-palette-btn-danger-main: #F25037;
$theme-palette-btn-danger-dark: #DF432B;

//Extras
$theme-palette-plus-main: #AD00FF;
$theme-palette-black: #000000;
$theme-shadow-soft: 7px 7px 71px -49px rgba(0,0,0,0.75);


