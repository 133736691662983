@import './vars.scss';
.request-payment-wrapper {
    width: 100%;
    margin-bottom: 50px!important;
    .request-paymen-heading {
        display:block;
        padding: $theme-spacing-unit * 4;
    };
    .request-payment-area {
        display:flex;
        @media screen and (max-width: $lg) {
            flex-direction: column;
        };
    };
    .request-payment-selector {
        flex: 1 1;
        display:flex;
        padding: 0 $theme-spacing-unit * 4;
        .button-selector {
            flex: 1 1;
            margin-right: $theme-spacing-unit * 2;
            height: 200px;
            background: $theme-palette-dark-light;
            border: 0;
            border-radius: $theme-radius;
            color: $theme-palette-dark-contrastText;
            display:flex;
            flex-direction: column;
            justify-content: center;
            text-align: left;
            padding: $theme-spacing-unit * 2;
            &:last-child {
                margin-right: 0;
            };
            &.active {
                background: $theme-palette-primary-main,
            }
        };
    };
    .request-payment-box {
        flex: 1 0;
        padding: 0 $theme-spacing-unit * 6;
        display:flex;
        flex-direction: column;
        @media screen and (max-width: $lg) {
            margin-top: 50px;
        };
        .request-info {
            flex-grow: 1;
            display: flex;
            @media screen and (max-width: $lg) {
                margin-bottom: 50px;
            };
          & > div {
              flex: 1 1;
              display:flex;
              align-items: center;
              &:first-child {
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
              };
              &:last-child {
                  flex: 60px 0;
              };
          };
        };
        .disclaimer-flow {
            text-align: center;
        }
        a:hover {
            cursor: pointer!important;
        }
        button {
            font-size: 1.5em!important;
        };
    };
};
