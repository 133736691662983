@import './vars.scss';
.logo-brand {
    display: flex;
    align-items:center;
    color: inherit;
    p {
        margin-left: $theme-spacing-unit;
    };
    .avatar {
        border-radius: 0%!important;
    };
    &.lg, &.full {
        .avatar {
            width: 50px;
            height: 50px;
        }
        p {
            font-size: 2.2em!important;
            font-weight: bold;
        }
    };
    &.xs {
        .avatar {
            width: 35px;
            height: 35px;
        }
        p {
            font-size: 1.2em;
            font-weight: 400;
        }
    };
    &.md{
        .avatar {
            width: 45px;
            height: 45px;
        }
        p {
            font-size: 2em;
            font-weight: 400;
        }
    };
};