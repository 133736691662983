@import './vars.scss';
.slideritem{
    width: 500px;
    height: 300px;
    flex-direction: row;
    border: 0;
    &.active {
        background: $theme-palette-primary-main;
        color: $theme-palette-primary-contrastText;
    }
    .card-img {
        flex: 1 1;
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
    .card-body{
        flex: 2 1;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
    }
    .slideritem-img {
        flex: 200px 0;
        overflow: hidden;
    }
}