@import './vars.scss';
.ci-item{
    border: 0;
    display: flex;
    min-height: calc(150px + #{$theme-spacing-unit});
    width: 100%;
    margin-bottom: $theme-spacing-unit * 4;
    padding-top: $theme-spacing-unit * 2;
    .ci-avatar {
        flex: 80px 0;
        justify-content: center;
        display: flex;
        padding-top: 25px;
        padding-right: $theme-spacing-unit;
    }
    .ci-content{
        flex: 1 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: 1px solid $theme-palette-grey-light;
        border-radius: $theme-radius;
        padding: calc(6px + #{$theme-spacing-unit});
        background: $theme-palette-primary-contrastText;
        & textarea{
            background-color: $theme-palette-grey-medium;
            border: 0;
            min-height: calc(130px + #{$theme-spacing-unit});
            color: $theme-palette-grey-main;
        }
        & .form-group:first-child{
            display: flex;
            flex-direction: column;
        }
        & .form-group{
            display: flex;
            flex-direction: row-reverse;

            textarea.form-control.is-invalid {
              background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem + 1em);
              border: 1px solid #dc3545;
            }
        }
    }
}
