@import "./vars.scss";

.pagination {
    margin: 30px 0;
    .page-item {
        .page-link {
            color: $theme-palette-primary-main;
        };
        &.active {
            .page-link {
                background: $theme-palette-primary-main;
                color: $theme-palette-primary-contrastText;
                border-color: $theme-palette-primary-main
            };
        };
    };
};