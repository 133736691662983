@import './vars.scss';
.ln-item{
    border: 1px $theme-palette-grey-muted solid; 
    display: flex;
    min-height: calc(80px + #{$theme-spacing-unit});
    padding: 0 $theme-spacing-unit 0 $theme-spacing-unit;
    margin-bottom: $theme-spacing-unit;
    @media screen and (max-width: $md) {
        position: relative;
        .ln-lastarea {
            div {
                position: absolute;
                right: $theme-spacing-unit / 2;
            }
            div:first-child {
                top: $theme-spacing-unit / 2;
            }
            div:last-child {
                bottom: $theme-spacing-unit / 2;
                h5 {
                    font-size: 80%;
                };
            };
        };
    };
    .ln-avatar {
        flex: 70px 0;
        align-items: center;
        justify-content: center;
        display: flex;
        cursor: pointer;
    }
    .ln-content{
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: $theme-spacing-unit * 2 $theme-spacing-unit;
        align-items: center;
        flex: 1 0;
        text-align: justify;
        @media screen and (max-width: $md) {
            flex: 1 1;
        };
        & div {
            display: block;
            width: 100%;
            word-break: break-all;
            text-overflow: ellipsis;
            &.ln-heading {
                span {
                    display: flex;
                    flex-direction: column;
                };
            };
        };
        & p {
            margin-top: $theme-spacing-unit;
            color: $theme-palette-dark-main;
        }
    }
    .ln-lastarea{
        flex: 230px 0;
        display: flex; 
        flex-direction: column;
        @media screen and (max-width: $md) {
            flex: auto 0;
        };
        & SVG{
            color: $theme-palette-grey-muted;
            margin-left: $theme-radius;
        }
        & small {
            color: $theme-palette-grey-main;
        }
        & div:first-child {
            flex: 30px 0;
            flex-direction: initial;
            justify-content: flex-end
        }
        & div {
            flex: 1 1;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
        }
    }
    .atention{
        & SVG{
            color: $theme-palette-primary-main;
        }
    }

}