@import './vars.scss';


.new-footer{
    display: flex;
    justify-content: center;
    .btn{
        margin-left: $theme-spacing-unit;
    }
}
.new-item{
    display: flex;
    min-height: calc(80px + #{$theme-spacing-unit});
    flex-direction: column;
    
    .new-avatar {
        flex: 70px 0;
        align-items: center;
        justify-content: center;
        display: flex;
        cursor: pointer;
    }
    .new-content{
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: $theme-spacing-unit;
        align-items: center;
        & div {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            flex: 1 1;
        }
    }
}

.bottom-item{
    display: flex;
    height: auto;
    bottom: $theme-spacing-unit * 2;
    right: $theme-spacing-unit * 2;
    position: fixed;
    background: $theme-palette-primary-contrastText;
    color: $theme-palette-alert-info-contrastText;
    border: 1px $theme-palette-grey-muted solid;
    width: 300px;
    border-radius: $theme-radius;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);
    .new-title{
        margin-bottom: $theme-radius;
        background-color: $theme-palette-primary-main;
        padding-left: $theme-radius;
        color: $theme-palette-primary-contrastText;
    };
    .new-body{
        display: flex;
        justify-content: center;
    }
    .new-action {
        flex: 1 1;
    }
    .new-avatar {
        flex: 70px 0;
        align-items: center;
        justify-content: center;
        display: flex;
        cursor: pointer;
    };
    
    .new-content{
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: $theme-spacing-unit;
        align-items: center;
        & div {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            flex: 1 1;
        }
    }
}
