@import './vars.scss';
.loading-fullscreen {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .spinner-border {
        width: 90px;
        height: 90px;
        margin-bottom: $theme-spacing-unit;
    };
};
.loading-component {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content:center;
    align-items: center;
};