@import './vars.scss';
.rankmodal-item{
    border: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 140px;
    .rankmodal-avatar {
        flex: 110px 0;
        justify-content: center;
        display: flex;
        padding-top: $theme-spacing-unit;
    }
    .rankmodal-rank{
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: calc(6px + #{$theme-spacing-unit});
    }
    .rankmodal-id{
        display: flex;
        justify-content: center;
    }
}