@import './vars.scss';
.breadcrumb-custom{
    background: none;
    padding: 0;

    a {
        color: $theme-palette-primary-main;
        &:hover {
            color: $theme-palette-primary-dark;
        }
    }
}