@import './vars.scss';
@import './breakpoints.scss';
.client-wrapper {
    height: 500px;
    display:flex;
    flex-direction: column;
    &.related-wrapper {
        height: 400px;
        .client-title-wrapper {
            flex: 110px 0;
            display:flex;
        };
    };
    .client-title-wrapper {
        flex: 200px 0;
        display: flex;
        align-items: center;
        justify-content: center;
    };
    .client-slider-wrapper {
        flex: 1 1;
        .swiper-slide-active {
            background: $theme-palette-primary-main;
            color: $theme-palette-primary-contrastText;
        };
    };
};

.swiper-container {
    height: 100%;
    display: flex;
    .swiper-wrapper {
        .swiper-slide {
            .container {
                height: 100%;
            };
        };
    };
};


.slider-item {
    display:flex;
    .slider-image {
        flex: 200px 0;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        };
    }
    .slider-info {
        flex: 1 1;
        padding: $theme-spacing-unit * 4;
        display: flex;
        justify-content: center;
        flex-direction: column;
    };
};




.area-wrapper-content {
    height: 85%;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: $xlg) {
        height: auto;
    };
    .swiper-container-horizontal .swiper-pagination-bullets {
        bottom: -5px;
        @media screen and (min-width: $md) {
            display: none;
        };
    };
    .swiper-slides-full {
        flex: 1 1;
        .wrapper-content-slide{
            .container {
                @media screen and (max-width: $md) {
                    flex-direction: column;
                };
            };
            .area-item {
                .image-wrapper {
                    width: 100%;
                    height: auto;
                };
                .content-wrapper {
                    @media screen and (max-width: $md) {
                        width: 90%;
                    };
                };
            };
            h1 {
                font-size: 1.8rem;
            };
        };
    };
    .swiper-slides-thumbs {
        flex: 50px 0;
        background: #f6f5f8;
        padding: 18px 0;
        @media screen and (max-width: $md) {
            display:none;
        };
        .wrapper-content-slide{
            .container {
                @media screen and (max-width: $md) {
                    flex-direction: column;
                };
            };
        };
    };
};

.wrapper-content-slide{
    display:flex;
    &.wrapper-content-block {
       height: 80%;
    };
    .container {
        height: 100%;
        display:flex;
    };
    &:nth-child(even){
        .area-item {
            &.thumbs {
                &:first-child {
                    order: 2;
                };
                &:last-child {
                    order: 2;
                };
            }
            &:first-child {
                order: 2;
            };
            &:last-child {
                order: 1;
            };
        };
    };
    .area-item {
        flex: 1 1;
        padding: $theme-spacing-unit * 2;
        display: flex;
        &:first-child {
            align-items: center;
        };
        &:last-child {
            align-items: center;
        };
        &.thumbs {
            cursor: pointer;
            background: white;
            &:first-child {
                flex: 100px 0;
                padding: 0;
                .image-wrapper {
                    width: 100%;
                    height: 100%;
                };
            };
        };
        .content-wrapper {
            width: 70%;
        };
        .image-wrapper {
            width: 80%;
            height: 80%;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover
            };
        };
        &:first-child {
            order: 1;
        };
        &:last-child {
            order: 2;
            justify-content: center;
            flex-direction: column;
        };
    };
};