@import './vars.scss';

.file-upload-wrapper {
    display: flex;
    flex-direction: column;
    border-radius: $theme-radius;
    .file-reason {
        display:flex;
        .file-reason-info {
            flex: 1 1;
        };
        .file-reason-action {
            flex: 1 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        };
    };
    .file-preview {
        padding: $theme-spacing-unit * 2 0;
    };
};
.preview-ie {
    height: 100%;
    width: 100%;
};
.preview-image-wrap {
    width: 200px;
    height: 200px;
    display: inline-flex;
    flex-direction: column;
    margin-right: $theme-spacing-unit;
    margin-bottom: $theme-spacing-unit;
    background: #eee;
    padding: 8px;
    &:last-child {
        margin-right: 0;
    };
    .pointer {
      &:hover {
          cursor: pointer;
      };
    }
    .preview-data {
        flex: 1 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align:center;
        div {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display:block;
            width: 100%;
            height: 40px;
            min-height: 40px;
            max-height: 40px;
        };
    };
    .preview-image {
        border-radius: $theme-radius;
        flex: 126px 0;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        };
    };
};
