@import './vars.scss';
.widget-rank {
    display:flex;
    height: 56px;
    align-items: stretch;
    padding: $theme-spacing-unit;
    &.ie {
        .rank {
            span {
                svg {
                    height: 45px!important;
                }
            }
        }
    }
    .rank {
        width: 100%;
        height: 100%;
        span {
            .empty {
                color: $theme-palette-grey-main;
            };
            .filled {
                color: $theme-palette-primary-main;
            };
            svg {
                width: 40px!important;
                height: 100%!important;
            };
        };
    };
};