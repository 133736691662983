@import './vars.scss';
.biitem-danger {
    background: $theme-palette-btn-danger-main;
    border-color: $theme-palette-btn-danger-main;
    border-radius: 4px;
    display: flex;
    align-items: center;
    color: $theme-palette-primary-contrastText;
    max-width: 300px;
    margin-right: $theme-spacing-unit;
    svg {
        color: $theme-palette-primary-contrastText;
        width: 14px!important;
        height: 100%!important;
    };
    &:hover {
        background: $theme-palette-btn-danger-dark;
        border-color: $theme-palette-btn-danger-dark;
    };

};

.textarea{
    flex-grow: 1;
    margin-left: $theme-spacing-unit * 3;
}

.iconarea{
    background: $theme-palette-btn-danger-dark;
    display: flex;
    flex: 40px 0;
    height: 100%;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
};
