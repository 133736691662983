@import './vars.scss';
.suggested-wrapper, .profile-wrapper {
    height: 225px;
    display:flex;
    background: $theme-palette-dark-main;
    margin-bottom: $theme-margin-wrapper;
    color: $theme-palette-primary-contrastText;
    .suggested-wrapper-area, .profile-wrapper-area {
        display: flex;
        height: 100%;
    };
    .suggested-area-left, .suggested-area-right, .profile-area-left, .profile-area-right {
        display: flex;
        flex: 1 1;
        align-items: center;
    };
    .suggested-area-right, .profile-area-right {
        justify-content: flex-end
    };
    .suggested-area-left, .profile-area-left {
        .item {
            flex: 180px 0;
            &:last-child {
                flex: 1 1;
                display:flex;
                flex-direction: column;
            };
        };
    };
};