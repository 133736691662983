@import './vars.scss';

.sign-wrapper {
    width: 100%;
    height: auto;
    display:block;
    border-bottom: 3px solid $theme-palette-border-gray;
    border-right: 1px solid $theme-palette-border-gray;
};
.sign-title {
    display:block;
    width: 100%;
    height: auto;
    background: $theme-palette-grey-medium;
    padding: $theme-spacing-unit * 2;
    border-bottom: 1px solid $theme-palette-border-gray;
};
.sign-content {
    padding: $theme-spacing-unit * 2;
    background: $theme-palette-dark-contrastText;
};