/*@media (min-width: 576px){
    .container {
        max-width: calc(540px - 100px);
    };
};

@media (min-width: 768px) {
    .container {
        max-width: calc(720px - 200px);
    };
};

@media (min-width: 992px) {
    .container {
        max-width: calc(960px - 200px);
    };
};

@media (min-width: 1200px){
    .container {
        max-width: calc(1200px - 200px);
    };
};*/
@import url(./latofonts.css);
.breadcrumb-custom {
  background: none;
  padding: 0; }
  .breadcrumb-custom a {
    color: #F25037; }
    .breadcrumb-custom a:hover {
      color: #DF432B; }

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%; }
  .avatar img {
    object-fit: cover;
    width: 100%;
    height: 100%; }
  .avatar.xs {
    width: 25px;
    height: 25px; }
  .avatar.sm {
    width: 45px;
    height: 45px; }
  .avatar.md {
    width: 65px;
    height: 65px; }
  .avatar.lg {
    width: 85px;
    height: 85px; }
  .avatar.xlg {
    width: 140px;
    height: 140px; }
    @media screen and (max-width: 720px) {
      .avatar.xlg {
        width: 90px;
        height: 90px; } }

.avatar-list {
  display: flex;
  padding: 8px; }
  .avatar-list .avatar-list-item {
    margin-left: -10px;
    height: 75px;
    width: 75px;
    border-radius: 50%;
    border: 10px solid #3F4448; }
    .avatar-list .avatar-list-item .avatar {
      margin-left: -5px;
      margin-top: -5px; }

.widget-rank {
  display: flex;
  height: 56px;
  align-items: stretch;
  padding: 8px; }
  .widget-rank.ie .rank span svg {
    height: 45px !important; }
  .widget-rank .rank {
    width: 100%;
    height: 100%; }
    .widget-rank .rank span .empty {
      color: #637282; }
    .widget-rank .rank span .filled {
      color: #F25037; }
    .widget-rank .rank span svg {
      width: 40px !important;
      height: 100% !important; }

.notify {
  background: #F1F8FF;
  border: 1px solid #D0D0D3;
  min-height: calc(40px + 8px);
  padding: 8px;
  display: flex;
  color: #24292E;
  flex-direction: column;
  word-wrap: break-word;
  margin-top: 8px; }

.notify-main {
  padding: 8px;
  border-bottom: 3px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  background: #fff;
  margin-bottom: 8px; }
  .notify-main p {
    font-size: 0.85em; }
  .notify-main .notify-buttonarea {
    display: flex;
    align-items: center;
    justify-content: center; }

.notify-sticky-wrapper {
  display: flex;
  bottom: 16px;
  right: 16px;
  position: fixed;
  background: #fff;
  color: #24292E;
  width: 350px;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23); }
  .notify-sticky-wrapper .notify-avatar {
    flex: 50px 0;
    padding: 8px; }
  .notify-sticky-wrapper .notify-body {
    flex: 1 1;
    padding: 0px 8px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .notify-sticky-wrapper .notify-action {
    flex: auto 0;
    display: flex;
    align-items: center;
    border-left: 1px solid #D0D0D3; }
    .notify-sticky-wrapper .notify-action button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background: #F25037;
      height: 100%;
      border: 0; }
      .notify-sticky-wrapper .notify-action button:hover {
        background: #DF432B;
        color: #fff; }

.slideritem {
  width: 500px;
  height: 300px;
  flex-direction: row;
  border: 0; }
  .slideritem.active {
    background: #F25037;
    color: #fff; }
  .slideritem .card-img {
    flex: 1 1;
    object-fit: cover;
    height: 100%;
    width: 100%; }
  .slideritem .card-body {
    flex: 2 1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column; }
  .slideritem .slideritem-img {
    flex: 200px 0;
    overflow: hidden; }

.alert {
  min-height: 40px;
  height: auto;
  padding: 0 4px 16px 12px;
  display: flex;
  color: #24292E;
  flex-direction: column;
  word-wrap: break-word;
  border: 1px solid #FFFFFF;
  margin: 8px 0 0 0;
  cursor: pointer;
  width: 100%; }
  .alert.alert-danger {
    background: #F25037;
    color: #fff; }
  .alert.alert-info {
    background: #FFFFFF;
    color: #24292E;
    border: 1px solid #E5E5E5; }
  .alert .alert-header {
    flex: 20px 0;
    display: flex; }
    .alert .alert-header .circle-info {
      color: black; }
    .alert .alert-header .alert-header-id {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      flex: 50px 1; }
    .alert .alert-header .alert-header-mix {
      display: flex;
      align-items: flex-start;
      flex: 130px 1;
      justify-content: flex-end;
      color: #D0D0D3; }
    .alert .alert-header .alert-header-mix-danger {
      display: flex;
      align-items: flex-start;
      flex: 130px 1;
      justify-content: flex-end;
      color: #fff; }
      .alert .alert-header .alert-header-mix-danger svg {
        color: #DF432B; }
  .alert .alert-content {
    flex: auto 1;
    display: flex; }
    .alert .alert-content div {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .alert .alert-content div:last-child {
        align-items: flex-end; }
    .alert .alert-content strong {
      font-size: 1em; }
  .alert p {
    padding-right: 4px; }

.alert-main {
  padding: 8px;
  margin-bottom: 24px;
  margin-top: 0;
  border: 1px solid #e5e5e5;
  background: #fff; }
  .alert-main.no-alert {
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center; }
    .alert-main.no-alert p {
      color: #637282; }
  .alert-main p {
    font-size: 0.85em; }

.alert-custom {
  flex-direction: row;
  padding: 8px;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(100% - 30px);
  margin-bottom: 8px;
  cursor: default; }
  .alert-custom .alert-icon {
    flex: 40px 0;
    display: flex;
    justify-content: center;
    align-items: center; }
  .alert-custom .alert-message {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center; }

.alert-dismissible .close {
  padding: 0.3rem 0.5rem; }

.alert-widget {
  font-size: 1rem; }

.alert-warning {
  border-color: #ffeeba !important; }

.alert-form {
  min-height: 5px !important;
  padding: 6px 12px 6px 12px !important; }
  .alert-form p {
    margin: 0px !important; }

.rankitem {
  border: 0;
  display: flex;
  min-height: calc(80px + 8px);
  padding: 8px;
  width: 100%;
  position: relative; }
  .rankitem .rank-avatar {
    flex: 100px 0;
    align-items: center;
    justify-content: center;
    display: flex; }
  .rankitem .rank-content {
    flex-grow: 1;
    display: flex;
    flex: 1 0;
    flex-direction: column;
    justify-content: center; }
    .rankitem .rank-content .rank-box {
      display: flex;
      align-items: center; }
      .rankitem .rank-content .rank-box:first-child {
        flex-direction: column;
        align-items: flex-start; }
      @media screen and (max-width: 720px) {
        .rankitem .rank-content .rank-box small {
          position: absolute;
          top: 0;
          right: 0; } }
  .rankitem .rank-rank {
    flex: 250px 0;
    align-items: center;
    justify-content: center;
    display: flex; }

.ar-item {
  border: 1px #D0D0D3 solid;
  display: flex;
  min-height: calc(80px + 8px);
  border-left: 8px solid transparent;
  margin-bottom: 8px;
  position: relative; }
  .ar-item.normal {
    border-left-color: #AD00FF; }
  .ar-item.plus {
    border-left-color: #89E051; }
  .ar-item .ar-avatar {
    flex: 70px 0;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer; }
  .ar-item .ar-content {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 16px 8px;
    align-items: center; }
    .ar-item .ar-content.ar-content-mobile {
      flex-direction: column;
      align-items: flex-start; }
      .ar-item .ar-content.ar-content-mobile .ar-content-mobile-date, .ar-item .ar-content.ar-content-mobile .ar-content-mobile-estimated {
        position: absolute;
        right: 8px; }
      .ar-item .ar-content.ar-content-mobile .ar-content-mobile-date {
        top: 0; }
      .ar-item .ar-content.ar-content-mobile .ar-content-mobile-estimated {
        bottom: 0; }
    .ar-item .ar-content div {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      flex: 1 1; }
  .ar-item .ar-buttonarea {
    flex: 200px 0;
    align-items: center;
    justify-content: flex-end;
    display: flex;
    padding-right: 16px; }
    .ar-item .ar-buttonarea.multiple-button {
      padding: 8px;
      flex-direction: column; }
    @media screen and (max-width: 720px) {
      .ar-item .ar-buttonarea {
        flex: auto 0; } }
  .ar-item .ari-lastarea {
    flex: 200px 0;
    display: flex; }
  .ar-item .ar-actionarea {
    flex: 200px 0;
    align-items: center;
    justify-content: flex-end;
    display: flex;
    padding-right: 16px; }
    @media screen and (max-width: 720px) {
      .ar-item .ar-actionarea {
        justify-content: flex-end; } }
    .ar-item .ar-actionarea .widget-rank {
      height: 46px; }
      .ar-item .ar-actionarea .widget-rank .rank span svg {
        width: 30px !important; }
  .ar-item .ari-info {
    display: flex;
    flex-direction: column;
    width: 170px; }
    .ar-item .ari-info div:first-child {
      flex: 30px 0;
      flex-direction: column;
      justify-content: left; }
    .ar-item .ari-info div {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      align-items: center; }
    .ar-item .ari-info .widget-rank .rank span svg {
      width: 30px !important; }
  .ar-item .ari-links {
    width: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .ar-item .ari-links svg {
      color: #D0D0D3;
      width: 20px !important;
      height: 100% !important;
      margin: 0;
      padding-bottom: 8px;
      cursor: pointer; }
  @media screen and (max-width: 720px) {
    .ar-item {
      flex-wrap: wrap; }
      .ar-item .ar-content {
        flex: calc(100% - 70px); }
      .ar-item .ari-lastarea {
        flex: 100%;
        margin-bottom: 20px; }
        .ar-item .ari-lastarea .ar-buttonarea, .ar-item .ari-lastarea .ar-actionarea {
          flex: 1 0;
          justify-content: center;
          padding: 0 8px; }
          .ar-item .ari-lastarea .ar-buttonarea h6, .ar-item .ari-lastarea .ar-actionarea h6 {
            font-weight: 800;
            font-size: 1.2rem; }
        .ar-item .ari-lastarea button.btn {
          width: 100%; }
          .ar-item .ari-lastarea button.btn:nth-child(1) {
            order: 1; }
          .ar-item .ari-lastarea button.btn:nth-child(2) {
            order: 2; } }

.user-info {
  background: #24292E;
  padding: 8px 0 8px 0;
  min-height: calc(200px + 8px);
  display: flex;
  color: #fff; }
  @media screen and (max-width: 720px) {
    .user-info {
      text-align: center; }
      .user-info h1 {
        font-size: 1.8rem; } }
  .user-info .container {
    display: flex; }
    @media screen and (max-width: 720px) {
      .user-info .container {
        flex-direction: column; } }
    .user-info .container .user-info-left {
      display: flex;
      flex: 1 1;
      align-items: center; }
      .user-info .container .user-info-left .user-info-avatar, .user-info .container .user-info-left .user-info-content {
        flex: 1 1; }
      .user-info .container .user-info-left .user-info-avatar {
        flex: 180px 0; }
        @media screen and (max-width: 720px) {
          .user-info .container .user-info-left .user-info-avatar {
            flex: 120px 0; } }
      .user-info .container .user-info-left .user-info-content {
        display: flex;
        flex-direction: column; }
        @media screen and (max-width: 720px) {
          .user-info .container .user-info-left .user-info-content {
            align-items: flex-start; } }
        .user-info .container .user-info-left .user-info-content .user-area-button {
          display: flex;
          margin-top: 8px; }
          .user-info .container .user-info-left .user-info-content .user-area-button .btn {
            margin-right: 8px; }
            .user-info .container .user-info-left .user-info-content .user-area-button .btn:last-child {
              margin-right: 0; }
    .user-info .container .user-info-right {
      flex: 1 1;
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      @media screen and (max-width: 720px) {
        .user-info .container .user-info-right {
          justify-content: center; } }
      .user-info .container .user-info-right .btn {
        margin-right: 8px; }
        .user-info .container .user-info-right .btn:last-child {
          margin-right: 0; }

.header.home {
  background: #1b1b1b;
  height: 90%;
  align-items: stretch;
  display: flex;
  position: relative;
  color: #fff; }
  @media screen and (max-width: 720px) {
    .header.home {
      flex-direction: column;
      min-height: 90%; } }
  .header.home .header-left {
    flex: 50% 1;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 50px;
    order: 1; }
    @media screen and (max-width: 720px) {
      .header.home .header-left {
        padding: 0 20px;
        flex: 120px 0;
        order: 2; }
        .header.home .header-left .header-item-left:first-child {
          display: none; }
        .header.home .header-left .header-item-left:last-child {
          display: none; } }
    .header.home .header-left .header-item-left {
      flex: 2 1;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .header.home .header-left .header-item-left:first-child {
        flex: 1 1; }
      .header.home .header-left .header-item-left:last-child {
        flex: 1 1; }
  .header.home .header-right {
    flex: 50% 1;
    position: relative;
    background: url(../images/lawyers.jpg) center center no-repeat;
    background-size: cover;
    order: 2; }
    @media screen and (max-width: 720px) {
      .header.home .header-right {
        order: 1;
        display: flex;
        flex-direction: column; }
        .header.home .header-right .header-icon-play {
          align-items: flex-end; }
          .header.home .header-right .header-icon-play .icon-play {
            width: 50px;
            height: 50px; }
            .header.home .header-right .header-icon-play .icon-play svg {
              width: 0.675em; } }
    .header.home .header-right:after {
      content: "";
      position: absolute;
      background: rgba(0, 0, 0, 0.4);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }

.header.private {
  height: 70px;
  z-index: 10; }
  .header.private .navbar-custom {
    background: #24292E; }

.header .header-item-wrapper {
  display: flex;
  flex: 2 1;
  align-items: flex-end;
  z-index: 2;
  position: relative;
  justify-content: center; }

.header .nav-wrapper {
  margin: 0; }

.search-wrapper-header {
  flex: 1 1;
  padding: 0 8px; }

.item-link-button {
  padding: 0.5rem 24px !important;
  border: 0; }
  .item-link-button.sigin {
    background: #24292E;
    margin-right: 8px;
    color: #fff; }
    .item-link-button.sigin:hover {
      background: #1b1b1b; }
  .item-link-button.register {
    background: #F25037;
    color: #fff; }
    .item-link-button.register:hover {
      background: #DF432B; }

.navbar-custom {
  position: fixed;
  width: 100%;
  height: 70px;
  z-index: 3;
  color: #fff;
  transition: 0.2s;
  padding: 0 50px; }
  @media screen and (max-width: 720px) {
    .navbar-custom {
      padding: 0 20px; } }
  .navbar-custom.sticky-header {
    background: rgba(0, 0, 0, 0.69); }
  .navbar-custom .nav-menu-left, .navbar-custom .navbar-collapse {
    flex: 1 1; }
    .navbar-custom .nav-menu-left.grow-left, .navbar-custom .navbar-collapse.grow-left {
      flex: auto 0; }
  .navbar-custom .nav-menu-left {
    display: flex; }
    .navbar-custom .nav-menu-left .menu-left .nav-item .nav-link {
      height: 100%; }
      .navbar-custom .nav-menu-left .menu-left .nav-item .nav-link:hover {
        text-decoration: underline; }
  .navbar-custom .navbar-brand, .navbar-custom .nav-link {
    color: inherit; }
  .navbar-custom .nav-link {
    display: flex;
    align-items: center; }

.nav-wrapper-stacked {
  width: 100%;
  height: auto;
  border: 1px solid #F8F8F9; }
  .nav-wrapper-stacked .nav-stacked-title {
    background: #e5e5e5;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 16px; }
  .nav-wrapper-stacked .nav .nav-item {
    height: 50px;
    display: flex; }
    .nav-wrapper-stacked .nav .nav-item:hover {
      background: #F8F8F9;
      cursor: pointer; }
    .nav-wrapper-stacked .nav .nav-item .nav-link {
      display: flex;
      padding-left: 32px;
      align-items: center;
      width: 100%;
      height: 100%;
      color: #637282; }
    .nav-wrapper-stacked .nav .nav-item .active {
      background: #F25037;
      color: #fff; }
  .nav-wrapper-stacked.nav-filter .nav-stacked-title {
    background: transparent;
    color: rgba(0, 0, 0, 0.87);
    font-weight: bolder; }
  .nav-wrapper-stacked.nav-filter .nav .nav-item .nav-link {
    color: #637282; }
    .nav-wrapper-stacked.nav-filter .nav .nav-item .nav-link div {
      flex: 1 1; }
      .nav-wrapper-stacked.nav-filter .nav .nav-item .nav-link div:first-child {
        flex: 40px 0; }
      .nav-wrapper-stacked.nav-filter .nav .nav-item .nav-link div:last-child {
        flex: auto 0; }
    .nav-wrapper-stacked.nav-filter .nav .nav-item .nav-link.active {
      color: #F25037;
      background: rgba(0, 0, 0, 0.04);
      font-weight: bolder; }

.nav-tabs {
  margin-bottom: 32px;
  font-size: 1.25em; }
  .nav-tabs .nav-link:hover {
    cursor: pointer; }

.header-item-slogan-wrap {
  margin: 16px 0px; }
  @media screen and (max-width: 720px) {
    .header-item-slogan-wrap {
      margin: 0; } }
  .header-item-slogan-wrap .item-slogan h1 {
    font-weight: 600;
    font-size: 2.6rem; }
  @media screen and (max-width: 720px) {
    .header-item-slogan-wrap .item-slogan h1 {
      text-align: center;
      font-weight: 600; } }

.hero-search-wrapper {
  z-index: 100; }

.item-foot-wrap {
  display: flex;
  align-items: center;
  margin: 16px 0px;
  flex-wrap: wrap; }
  @media screen and (max-width: 720px) {
    .item-foot-wrap {
      justify-content: center; } }
  .item-foot-wrap .item-foot {
    flex: auto 0;
    display: flex;
    align-items: center;
    margin-right: 8px; }
    .item-foot-wrap .item-foot h6 {
      padding-left: 8px; }

.dropdown-item.active, .dropdown-item:active {
  background: #F25037;
  color: #fff; }

.phone-wrapper-break {
  width: 100%;
  padding-top: 80px;
  height: 100%;
  position: relative;
  z-index: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  @media screen and (max-width: 720px) {
    .phone-wrapper-break {
      padding: 0 8px; } }
  .phone-wrapper-break h1 {
    font-size: 1.8rem; }
  .phone-wrapper-break .login-wrapper-break {
    padding: 8px;
    display: flex;
    justify-content: center; }

.header-icon-play {
  height: 100%;
  width: 100%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1; }
  .header-icon-play .icon-play {
    background: white;
    border: 0;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px; }
    .header-icon-play .icon-play:focus {
      outline: 0; }
  .header-icon-play .icon-toggle {
    position: absolute;
    right: 20px;
    bottom: 20px;
    color: white;
    background: transparent;
    border: 0; }
    .header-icon-play .icon-toggle:focus {
      outline: 0; }

.nav-tabs {
  border: 0; }
  .nav-tabs .nav-item {
    cursor: default; }
  .nav-tabs:hover {
    color: inherit; }
  .nav-tabs .nav-link.active {
    background: transparent;
    border: 0;
    cursor: default;
    font-weight: bold; }

.status {
  display: flex;
  min-height: 140px;
  height: auto; }
  @media screen and (max-width: 720px) {
    .status {
      height: auto;
      flex-direction: column;
      padding: 20px 0; } }
  .status .status-area-left {
    flex: 1 1;
    display: flex;
    justify-content: center;
    flex-direction: column; }
  .status .status-area-right {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: flex-end; }
    @media screen and (max-width: 720px) {
      .status .status-area-right {
        justify-content: flex-start; } }
  .status .status-box-request {
    display: flex;
    margin: 16px 0 8px 0;
    align-items: center; }
    .status .status-box-request div .status-tag {
      margin-right: 20px; }
  .status .status-tag {
    border-radius: 4px;
    padding: 16px 48px 16px 48px; }

.ln-item {
  border: 1px #D0D0D3 solid;
  display: flex;
  min-height: calc(80px + 8px);
  padding: 0 8px 0 8px;
  margin-bottom: 8px; }
  @media screen and (max-width: 720px) {
    .ln-item {
      position: relative; }
      .ln-item .ln-lastarea div {
        position: absolute;
        right: 4px; }
      .ln-item .ln-lastarea div:first-child {
        top: 4px; }
      .ln-item .ln-lastarea div:last-child {
        bottom: 4px; }
        .ln-item .ln-lastarea div:last-child h5 {
          font-size: 80%; } }
  .ln-item .ln-avatar {
    flex: 70px 0;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer; }
  .ln-item .ln-content {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 16px 8px;
    align-items: center;
    flex: 1 0;
    text-align: justify; }
    @media screen and (max-width: 720px) {
      .ln-item .ln-content {
        flex: 1 1; } }
    .ln-item .ln-content div {
      display: block;
      width: 100%;
      word-break: break-all;
      text-overflow: ellipsis; }
      .ln-item .ln-content div.ln-heading span {
        display: flex;
        flex-direction: column; }
    .ln-item .ln-content p {
      margin-top: 8px;
      color: #24292E; }
  .ln-item .ln-lastarea {
    flex: 230px 0;
    display: flex;
    flex-direction: column; }
    @media screen and (max-width: 720px) {
      .ln-item .ln-lastarea {
        flex: auto 0; } }
    .ln-item .ln-lastarea SVG {
      color: #D0D0D3;
      margin-left: 4px; }
    .ln-item .ln-lastarea small {
      color: #637282; }
    .ln-item .ln-lastarea div:first-child {
      flex: 30px 0;
      flex-direction: initial;
      justify-content: flex-end; }
    .ln-item .ln-lastarea div {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center; }
  .ln-item .atention SVG {
    color: #F25037; }

.timer {
  display: flex;
  flex-direction: column; }

.text-danger {
  color: #F25037 !important; }

.text-secondary {
  color: #24292E; }

.text-primary {
  color: #000 !important; }

.error-text h1 {
  font-size: 8rem; }

.copyright-link {
  color: #e5e5e5;
  text-decoration: underline !important; }
  .copyright-link:hover {
    color: #F25037 !important; }

.btn {
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: 14px;
  padding: 0 32px 0 32px; }
  .btn:focus {
    box-shadow: none !important; }
  .btn .spinner-border {
    flex: 25px 0 !important;
    height: 25px;
    margin-left: 8px; }
  .btn.btn-sm {
    height: 30px; }
  .btn.btn-lg {
    height: 56px; }

.btn-success {
  background: #89E051;
  border-color: #89E051; }
  .btn-success:hover {
    background: #7dd246;
    border-color: #7dd246; }

.btn-danger {
  background: #F25037;
  border-color: #F25037; }
  .btn-danger:hover {
    background: #DF432B;
    border-color: #DF432B; }

.btn-square {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  padding: 0; }

.btn-linkedin {
  background: transparent;
  display: flex;
  align-items: center;
  color: #24292E; }
  .btn-linkedin svg {
    color: #0077B5;
    font-size: 36px; }

.btn-secondary {
  background: #24292E;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  align-items: center; }
  .btn-secondary svg {
    margin-left: 8px;
    color: #0077B5;
    font-size: 40px; }

.action-control {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
  padding: 10px 0; }
  .action-control .btn-action {
    padding: 0.25rem 0.5rem !important;
    margin-right: 8px;
    width: 45px; }
    .action-control .btn-action:hover {
      color: #F25037; }
    .action-control .btn-action:last-child {
      margin-right: 0px; }
    .action-control .btn-action svg {
      width: 100%; }
    .action-control .btn-action .loading-component .spinner-border {
      margin-left: 0; }

.link {
  text-align: left;
  padding: 0; }
  .link svg {
    margin-left: 8px; }

.text-link {
  color: #24292E;
  text-decoration: underline; }
  .text-link:hover {
    color: #000; }

.break-word {
  word-break: break-word; }

.btn-action-button {
  padding: 5px 10px;
  margin-left: 8px; }
  .btn-action-button span {
    display: flex; }
    .btn-action-button span small {
      margin-right: 8px; }
    .btn-action-button span svg {
      margin-left: 8px; }
  .btn-action-button.btn-action-success {
    background: #e5f7e8;
    color: #43ba52; }
  .btn-action-button.btn-action-cancel {
    background: #fbdbcf;
    color: #bd2d12; }
  .btn-action-button.btn-action-edit {
    background: #fbfae6;
    color: #aa7d16; }
  .btn-action-button.btn-action-comment {
    background: #daeff8;
    color: #3190af; }

.profile-main {
  display: block;
  height: auto;
  margin-bottom: 8px; }
  .profile-main .profile-rank {
    flex: 1 1;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 100px;
    margin-bottom: 32px;
    margin-top: 32px; }
    .profile-main .profile-rank .profile-rank-right {
      display: flex;
      flex: 50% 1;
      align-items: center;
      justify-content: center; }
      .profile-main .profile-rank .profile-rank-right small {
        width: 35%;
        margin-left: 16px; }
      .profile-main .profile-rank .profile-rank-right svg {
        color: #F25037;
        width: 30px !important;
        height: 100% !important;
        margin: 8px; }
    .profile-main .profile-rank .profile-rank-left {
      display: flex;
      flex: 50% 1;
      align-items: center;
      justify-content: center; }
      .profile-main .profile-rank .profile-rank-left small {
        width: 35%;
        margin-left: 16px; }
  .profile-main .profile-rank-list {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px;
    align-items: center;
    border: 1px #D0D0D3 solid;
    border-bottom: 0; }
  .profile-main .profile-rank-footer {
    border: 1px #D0D0D3 solid;
    border-top: 0;
    background-color: #F8F8F9;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
    cursor: pointer; }
  .profile-main .off {
    padding: 0; }

.comment-item {
  border: 0;
  display: flex;
  margin-bottom: 16px;
  width: 100%; }
  .comment-item .comment-avatar {
    flex: 80px 0;
    justify-content: center;
    display: flex;
    padding-right: 8px; }
  .comment-item .comment-content-right {
    margin-right: 80px; }
  .comment-item .comment-content-left {
    margin-left: 80px; }
  .comment-item .comment-text {
    position: relative;
    padding: 16px; }
    .comment-item .comment-text .comment-text-footer {
      position: absolute;
      right: 8px;
      padding: 8px;
      bottom: 0px; }
  .comment-item .comment-content {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    word-break: break-all;
    background: #fff; }
    .comment-item .comment-content div:first-child {
      display: flex;
      align-items: flex-start;
      flex: auto 0;
      color: #637282; }
      .comment-item .comment-content div:first-child strong {
        color: #24292E; }
      .comment-item .comment-content div:first-child p {
        margin-top: 4px;
        margin-left: 4px; }
    .comment-item .comment-content div {
      flex: 1 1;
      display: flex;
      align-items: center;
      padding: 16px; }
      .comment-item .comment-content div p {
        margin: 8px; }

.ci-item {
  border: 0;
  display: flex;
  min-height: calc(150px + 8px);
  width: 100%;
  margin-bottom: 32px;
  padding-top: 16px; }
  .ci-item .ci-avatar {
    flex: 80px 0;
    justify-content: center;
    display: flex;
    padding-top: 25px;
    padding-right: 8px; }
  .ci-item .ci-content {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    padding: calc(6px + 8px);
    background: #fff; }
    .ci-item .ci-content textarea {
      background-color: #F8F8F9;
      border: 0;
      min-height: calc(130px + 8px);
      color: #637282; }
    .ci-item .ci-content .form-group:first-child {
      display: flex;
      flex-direction: column; }
    .ci-item .ci-content .form-group {
      display: flex;
      flex-direction: row-reverse; }
      .ci-item .ci-content .form-group textarea.form-control.is-invalid {
        background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem + 1em);
        border: 1px solid #dc3545; }

.avatar-ticket-item {
  display: flex; }

.avatar-item {
  margin-left: -16px;
  border: 5px solid #fff; }

.request-doc-wrapper {
  display: flex;
  flex-wrap: wrap; }
  .request-doc-wrapper .request-last-wrapper {
    flex: 100% 0;
    height: 100px;
    display: flex;
    padding: 16px;
    border: 1px solid #e5e5e5;
    margin-bottom: 8px;
    background: #fff; }
    .request-doc-wrapper .request-last-wrapper .item {
      flex: 1 1;
      display: flex;
      align-items: center; }
      .request-doc-wrapper .request-last-wrapper .item:first-child {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center; }
      .request-doc-wrapper .request-last-wrapper .item:last-child {
        flex-grow: 1 1;
        display: flex;
        justify-content: flex-end; }
      .request-doc-wrapper .request-last-wrapper .item .in-revision {
        background: #fffff1;
        width: 100%;
        height: 100%;
        border: 1px solid #e5e5e5;
        padding: 8px;
        margin-top: 16px; }
  .request-doc-wrapper .no-bottom {
    margin-bottom: 0; }
  .request-doc-wrapper .disableddiv {
    pointer-events: none;
    opacity: 0.2; }
  .request-doc-wrapper .form-inline {
    width: 100%; }
  .request-doc-wrapper .withRevision {
    height: 100%; }
    .request-doc-wrapper .withRevision small {
      word-wrap: break-word;
      text-align: justify;
      text-overflow: ellipsis; }
  .request-doc-wrapper .revision {
    height: auto;
    flex-direction: column; }
    .request-doc-wrapper .revision .item {
      height: 90%; }
      .request-doc-wrapper .revision .item textarea {
        height: 150px;
        width: 100%; }
    .request-doc-wrapper .revision .row {
      margin-top: 16px; }
  .request-doc-wrapper .request-last-up-down {
    border: 1px solid #e5e5e5;
    flex: 1 0;
    display: flex;
    padding: 16px;
    background: #fff;
    margin-right: 8px !important; }
    @media screen and (max-width: 720px) {
      .request-doc-wrapper .request-last-up-down {
        flex: 100% 0;
        margin-right: 0 !important; } }
    .request-doc-wrapper .request-last-up-down:last-child {
      margin-right: 0; }
      .request-doc-wrapper .request-last-up-down:last-child .item svg {
        color: #F25037; }
    .request-doc-wrapper .request-last-up-down .item {
      flex: 1 1; }
      .request-doc-wrapper .request-last-up-down .item:last-child {
        flex: 80px 0;
        display: flex;
        justify-content: center;
        align-items: center; }
        .request-doc-wrapper .request-last-up-down .item:last-child .icon {
          width: 70px;
          height: 70px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 4rem; }
          .request-doc-wrapper .request-last-up-down .item:last-child .icon a:hover {
            cursor: pointer; }
          .request-doc-wrapper .request-last-up-down .item:last-child .icon .spinner-border {
            width: 4rem;
            height: 4rem; }
  .request-doc-wrapper .alert-custom {
    margin-left: 0;
    margin-right: 0;
    width: 100%; }

.bbr-wrapper {
  margin-bottom: 8px; }

.request-doc-widget .request-last-up-down {
  margin-bottom: 24px;
  margin-right: 0px; }

.request-doc-widget .request-last-wrapper {
  margin-bottom: 24px;
  margin-right: 0px;
  height: 100%;
  flex-direction: column;
  border-bottom: 3px solid #e5e5e5;
  border-right: 1px solid #e5e5e5; }
  .request-doc-widget .request-last-wrapper .item:last-child {
    justify-content: center; }
    .request-doc-widget .request-last-wrapper .item:last-child button {
      width: 100%; }
  .request-doc-widget .request-last-wrapper .in-revision {
    margin-bottom: 16px; }

.request-doc-widget .bbr-item {
  margin-top: 0px;
  flex-direction: row;
  border-bottom: 3px solid #e5e5e5;
  border-right: 1px solid #e5e5e5; }
  .request-doc-widget .bbr-item .bbr-text {
    padding: 0px;
    margin-left: 0px; }
  .request-doc-widget .bbr-item .bbr-button {
    flex: auto 0; }

.request-doc-widget .revision-open {
  margin-bottom: 0px; }

.request-doc-widget .alert-custom {
  margin-bottom: 24px;
  margin-top: 0px; }

.request-doc-widget .revision-action {
  justify-content: center !important; }

.custom-actions-wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
  background: #fff;
  border-right: 1px #D0D0D3 solid;
  border-left: 1px #D0D0D3 solid;
  border-bottom: 1px #D0D0D3 solid;
  margin-bottom: 8px; }

.hero-search {
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: 0.2s; }
  @media screen and (max-width: 720px) {
    .hero-search:not(.hero-search-home) {
      background: #fff !important;
      color: #24292E; }
    .hero-search .hero-form-wrapper form .form-group .form-control {
      background: inherit !important;
      color: #24292E !important; }
      .hero-search .hero-form-wrapper form .form-group .form-control::placeholder {
        color: #24292E !important; }
    .hero-search .btn-dark {
      background: inherit !important;
      color: inherit !important;
      border: 0; }
    .hero-search.active-search {
      position: fixed;
      left: 0;
      right: 0;
      height: 70px !important;
      top: 0;
      transform: translateY(0px);
      z-index: 10;
      box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.5); }
    .hero-search.inactive-search {
      position: fixed;
      left: 0;
      right: 0;
      height: 70px !important;
      top: 0;
      transform: translateY(-70px);
      z-index: 10; } }
  .hero-search.hero-search-fixed {
    position: fixed;
    top: 70px;
    z-index: 1;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.69); }
  .hero-search.compact-mode {
    height: 36px; }
    .hero-search.compact-mode .hero-form-wrapper form .form-group .form-control {
      font-size: 14px !important; }
      .hero-search.compact-mode .hero-form-wrapper form .form-group .form-control::placeholder {
        font-size: 14px !important; }
    .hero-search.compact-mode .hero-form-wrapper form .btn-danger {
      background: white;
      color: black;
      border-color: white;
      font-size: 14px; }
    .hero-search.compact-mode.dark {
      background: #3F4448;
      border-radius: 4px; }
      .hero-search.compact-mode.dark .hero-form-wrapper {
        background: inherit;
        border-color: #3F4448; }
        .hero-search.compact-mode.dark .hero-form-wrapper form .form-group .form-control {
          background: #3F4448;
          color: #fff; }
          .hero-search.compact-mode.dark .hero-form-wrapper form .form-group .form-control::placeholder {
            color: #fff; }
        .hero-search.compact-mode.dark .hero-form-wrapper form .btn-dark {
          background: #3F4448;
          border-color: #3F4448;
          font-size: 14px; }
    .hero-search.compact-mode .hero-form-wrapper form .form-group {
      padding-right: 50px; }
      .hero-search.compact-mode .hero-form-wrapper form .form-group .form-control {
        font-size: 18px; }
        .hero-search.compact-mode .hero-form-wrapper form .form-group .form-control::placeholder {
          font-size: 18px; }
    .hero-search.compact-mode .hero-form-wrapper form .btn {
      flex: 50px 0;
      padding: 0; }
  .hero-search.hero-mode {
    height: 125px; }
    @media screen and (max-width: 720px) {
      .hero-search.hero-mode {
        height: 100px;
        z-index: 2; } }
    .hero-search.hero-mode .hero-form-wrapper form .form-group {
      padding-right: 100px; }
      .hero-search.hero-mode .hero-form-wrapper form .form-group .form-control {
        font-size: 24px; }
        @media screen and (max-width: 720px) {
          .hero-search.hero-mode .hero-form-wrapper form .form-group .form-control {
            font-size: 18px; } }
        .hero-search.hero-mode .hero-form-wrapper form .form-group .form-control::placeholder {
          font-size: 18px; }
    .hero-search.hero-mode .hero-form-wrapper form .btn {
      flex: 200px 0; }
      @media screen and (max-width: 720px) {
        .hero-search.hero-mode .hero-form-wrapper form .btn {
          flex: auto 0; }
          .hero-search.hero-mode .hero-form-wrapper form .btn span {
            display: none; }
          .hero-search.hero-mode .hero-form-wrapper form .btn svg {
            margin-left: 0; } }
  .hero-search .hero-form-wrapper {
    flex: 1 1;
    background: #fff;
    display: flex; }
    .hero-search .hero-form-wrapper form {
      width: 100%;
      display: flex;
      border-radius: 4px; }
      .hero-search .hero-form-wrapper form .form-group {
        flex: 1 1;
        margin: 0;
        position: relative; }
        .hero-search .hero-form-wrapper form .form-group .form-control {
          height: 100%;
          border: 0;
          color: #24292E;
          border-radius: 0;
          padding-left: 32px;
          position: relative; }
          .hero-search .hero-form-wrapper form .form-group .form-control::placeholder {
            color: #24292E; }
          .hero-search .hero-form-wrapper form .form-group .form-control:focus {
            box-shadow: none; }
      .hero-search .hero-form-wrapper form .loading-icon {
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        width: 100px;
        height: 100%; }
      .hero-search .hero-form-wrapper form .btn {
        border-radius: 0;
        height: 100%;
        font-size: 24px; }
        .hero-search .hero-form-wrapper form .btn .button-position {
          margin-left: 8px;
          margin-top: 3px; }
  .hero-search .hero-help {
    flex: 50px 0;
    display: flex;
    justify-content: flex-end; }
    @media screen and (max-width: 720px) {
      .hero-search .hero-help {
        justify-content: center; } }
    .hero-search .hero-help .btn-link {
      color: #fff;
      text-decoration: underline;
      background: transparent;
      padding: 0; }

.search-result {
  background: #fff;
  height: auto;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  position: absolute;
  left: 0;
  right: 0;
  color: #24292E;
  max-height: 400px;
  overflow-y: auto;
  margin-top: -2px;
  border: 1px solid #fff; }

.search-result-item {
  display: flex;
  flex-direction: column;
  padding: 8px 32px 8px 32px;
  margin-bottom: 8px;
  cursor: pointer;
  transition: 0.2s; }
  .search-result-item:hover {
    background: #e5e5e5; }
  .search-result-item .search-result-item-title {
    margin-bottom: 4px; }
  .search-result-item.more {
    padding: 0; }
    .search-result-item.more:hover {
      background: transparent; }
    .search-result-item.more .btn {
      flex: 80px 0 !important;
      font-size: 14px !important; }

.rankmodal-item {
  border: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 140px; }
  .rankmodal-item .rankmodal-avatar {
    flex: 110px 0;
    justify-content: center;
    display: flex;
    padding-top: 8px; }
  .rankmodal-item .rankmodal-rank {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: calc(6px + 8px); }
  .rankmodal-item .rankmodal-id {
    display: flex;
    justify-content: center; }

.bbr-item {
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #e5e5e5;
  padding: 8px;
  min-height: 90px;
  margin-top: 24px; }
  .bbr-item .bbr-text {
    flex: 1 1;
    padding: 8px;
    margin-left: 16px; }
  .bbr-item .bbr-button {
    flex: 110px 0; }

.loading-fullscreen {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .loading-fullscreen .spinner-border {
    width: 90px;
    height: 90px;
    margin-bottom: 8px; }

.loading-component {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.modal-backdrop.show {
  opacity: 0.95; }

.modal-request {
  max-width: 70%;
  margin: auto;
  padding: 100px 0;
  position: relative; }
  @media screen and (max-width: 720px) {
    .modal-request {
      max-width: 100%; } }
  @media screen and (max-width: 960px) {
    .modal-request {
      max-width: 90%; } }
  .modal-request .modal-content {
    background: transparent;
    color: #fff;
    border: 0; }
  .modal-request .modal-header {
    border: 0; }
  .modal-request .modal-body {
    background: #fff;
    color: #24292E;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    padding: 32px; }
  .modal-request .modal-footer {
    border: 0;
    background: #24292E;
    color: #fff;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 0; }
  .modal-request .close-modal {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -50px;
    right: 0; }
    .modal-request .close-modal button {
      background: transparent;
      border: 0;
      color: #ffffff;
      font-size: 25px; }

.modal-request-header {
  display: flex;
  align-items: stretch;
  padding: 0;
  margin-bottom: 8px; }
  @media screen and (max-width: 720px) {
    .modal-request-header {
      flex-direction: column; } }
  .modal-request-header .modal-request-ticket {
    display: flex;
    flex: 1 1; }
    @media screen and (max-width: 720px) {
      .modal-request-header .modal-request-ticket {
        margin-bottom: 16px; } }
  .modal-request-header .modal-request-info {
    display: flex;
    flex: 1 1; }
    @media screen and (max-width: 720px) {
      .modal-request-header .modal-request-info {
        flex-direction: column; } }
    .modal-request-header .modal-request-info div {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      display: flex;
      justify-content: center; }
      @media screen and (max-width: 720px) {
        .modal-request-header .modal-request-info div {
          margin-bottom: 8px; } }
      .modal-request-header .modal-request-info div.modal-request-plus {
        background: #AD00FF;
        border-radius: 4px;
        padding: 0 8px; }
  .modal-request-header .modal-request-title {
    margin-left: 8px;
    flex-grow: 1; }
    .modal-request-header .modal-request-title p {
      word-break: break-all; }
  .modal-request-header .text-tags {
    color: #F25037;
    font-weight: 700; }

.modal-request-body {
  font-size: 1.1em; }
  .modal-request-body .file-wrapper {
    margin-bottom: 16px; }
    .modal-request-body .file-wrapper label {
      text-decoration: underline;
      margin-bottom: 0px; }
      .modal-request-body .file-wrapper label:hover {
        cursor: pointer; }
    .modal-request-body .file-wrapper .file-uploaded {
      font-size: 0.85em; }
      .modal-request-body .file-wrapper .file-uploaded span {
        margin-right: 8px; }
      .modal-request-body .file-wrapper .file-uploaded .spacing-left {
        margin-left: 8px;
        cursor: pointer; }
  .modal-request-body input[type=file] {
    display: none; }
  .modal-request-body p {
    margin-bottom: 16px; }
  .modal-request-body .form-group {
    position: relative; }
    .modal-request-body .form-group label {
      width: calc(100%-40px);
      width: 95%;
      color: #637282;
      margin-bottom: 14px; }
    .modal-request-body .form-group .form-check-label {
      margin-left: 24px;
      color: #24292E;
      width: 100%; }
    .modal-request-body .form-group .btn-tooltip {
      height: 40px;
      width: 40px;
      top: 0;
      right: 0;
      position: absolute;
      margin-right: -10px; }
    .modal-request-body .form-group .btn-action {
      font-size: 1em;
      color: #637282; }
      .modal-request-body .form-group .btn-action:hover {
        color: #F25037; }
    .modal-request-body .form-group p {
      color: #637282; }

.modal-footer > :not(:first-child) {
  margin: 0; }

.modal-notify-request .modal-content {
  background: transparent;
  padding-top: 100px; }
  .modal-notify-request .modal-content .modal-footer, .modal-notify-request .modal-content .modal-body {
    border: 0;
    justify-content: center;
    display: flex; }
  .modal-notify-request .modal-content .modal-body {
    flex-direction: column;
    align-items: center;
    background: #24292E;
    color: #ffffff;
    padding-bottom: 20px;
    border-top-right-radius: 0.3rem;
    border-top-left-radius: 0.3rem; }
    .modal-notify-request .modal-content .modal-body .avatar {
      margin-bottom: 20px;
      margin-top: -50px; }
    .modal-notify-request .modal-content .modal-body .fields {
      padding-top: 8px;
      width: 90%; }
      .modal-notify-request .modal-content .modal-body .fields .invalid-feedback {
        display: block;
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: #dc3545; }
    .modal-notify-request .modal-content .modal-body .request-last-wrapper {
      height: 80px;
      margin-top: 8px; }
    .modal-notify-request .modal-content .modal-body .request-last-up-down {
      height: auto;
      margin-top: 8px; }
      .modal-notify-request .modal-content .modal-body .request-last-up-down .item {
        display: flex;
        flex-direction: column;
        align-items: flex-start; }
    .modal-notify-request .modal-content .modal-body .is-invalid {
      border-color: #dc3545; }
    .modal-notify-request .modal-content .modal-body p {
      margin-top: 16px;
      text-align: center !important;
      font-size: 1.1em; }
  .modal-notify-request .modal-content .no-padding-botton {
    padding-bottom: 0px; }
  .modal-notify-request .modal-content .modal-footer {
    background: #24292E;
    padding: 0; }
    .modal-notify-request .modal-content .modal-footer .new-footer {
      width: 100%;
      display: flex;
      padding: 16px; }
      .modal-notify-request .modal-content .modal-footer .new-footer button {
        flex: 1 1;
        margin: 0;
        height: 70px; }
        .modal-notify-request .modal-content .modal-footer .new-footer button:first-child {
          margin-right: 8px; }
        .modal-notify-request .modal-content .modal-footer .new-footer button:last-child {
          margin-left: 8px;
          background: transparent;
          border-color: #3F4448;
          color: #D0D0D3; }
          .modal-notify-request .modal-content .modal-footer .new-footer button:last-child:hover {
            border-color: #D0D0D3;
            color: #ffffff; }

.logo-brand {
  display: flex;
  align-items: center;
  color: inherit; }
  .logo-brand p {
    margin-left: 8px; }
  .logo-brand .avatar {
    border-radius: 0% !important; }
  .logo-brand.lg .avatar, .logo-brand.full .avatar {
    width: 50px;
    height: 50px; }
  .logo-brand.lg p, .logo-brand.full p {
    font-size: 2.2em !important;
    font-weight: bold; }
  .logo-brand.xs .avatar {
    width: 35px;
    height: 35px; }
  .logo-brand.xs p {
    font-size: 1.2em;
    font-weight: 400; }
  .logo-brand.md .avatar {
    width: 45px;
    height: 45px; }
  .logo-brand.md p {
    font-size: 2em;
    font-weight: 400; }

.modal-login {
  background: #fff;
  display: flex;
  width: 100%;
  margin: 0 !important;
  min-height: 700px;
  color: #24292E; }
  @media screen and (max-width: 960px) {
    .modal-login .image-login {
      display: none; }
    .modal-login .form-login {
      padding: 32px; } }
  .modal-login .form-login {
    flex: 1 1; }
  .modal-login .image-login {
    flex: 1 1;
    position: relative; }
    .modal-login .image-login img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .modal-login .image-login span {
      position: absolute;
      bottom: 10%;
      background: #F25037;
      padding: 32px;
      width: 90%;
      left: 0;
      color: #fff; }

.form-control {
  border-radius: 4px; }

.buttons-form-action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 150px; }
  .buttons-form-action button {
    margin-right: 8px; }
    .buttons-form-action button:last-child {
      margin-right: 0; }

.myaccount-wrapper {
  border: 1px solid #e5e5e5; }
  .myaccount-wrapper .form-group {
    padding: 32px 16px;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 0;
    /*label {
            color: $theme-palette-grey-main
        };*/ }
    .myaccount-wrapper .form-group .form-control {
      color: #000000; }
    .myaccount-wrapper .form-group:last-child {
      border: 0; }
  .myaccount-wrapper .form-toggle-options {
    border: 0;
    padding: 16px 16px 0 16px; }
    .myaccount-wrapper .form-toggle-options .flex-right {
      display: flex;
      align-items: center; }
      .myaccount-wrapper .form-toggle-options .flex-right label {
        padding-top: 3px;
        color: #000000; }

.form-group-center {
  align-items: center; }

.login-wrapper {
  padding: 0 64px; }
  @media screen and (max-width: 720px) {
    .login-wrapper {
      padding: 0; } }
  .login-wrapper .login-title {
    margin-bottom: 16px; }
  .login-wrapper .helper-text-register {
    padding: 8px 0;
    display: flex;
    justify-content: center;
    align-items: center; }

.alert {
  padding: 16px;
  margin-left: 0;
  margin-right: 0;
  width: 100%; }
  .alert .alert-message {
    flex: auto;
    display: flex;
    align-items: center;
    text-align: center; }

.login-social-wrapper {
  display: flex;
  justify-content: center;
  align-items: center; }
  .login-social-wrapper .login-social-item {
    flex: 60px 0;
    margin-right: 16px;
    height: 60px;
    padding: 8px;
    transition: 0.2s;
    cursor: pointer; }
    .login-social-wrapper .login-social-item:hover {
      padding: 0; }
    .login-social-wrapper .login-social-item img {
      width: 100%;
      height: auto;
      object-fit: cover; }
    .login-social-wrapper .login-social-item:last-child {
      margin-right: 0; }

.account-recover-wrapper {
  max-width: 460px;
  width: calc(100% - 40px);
  padding: 44px;
  margin: 0 auto;
  background: #fff;
  position: relative;
  z-index: 1; }

.account-recover-logo .logo-brand p {
  margin-left: 8px;
  font-size: 1.25rem; }

.account-recover-logo a.btn {
  margin-top: 8px; }

.account-recover-area {
  width: 100%;
  height: auto;
  padding: 20px 0px; }

.bg-item {
  border-bottom: 1px #D0D0D3 solid;
  display: flex;
  width: 100%; }
  .bg-item:first-child {
    border-top: 1px #D0D0D3 solid; }
  .bg-item:hover {
    background: #e5e5e5;
    cursor: pointer; }
  .bg-item .bg-text {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    padding: 16px;
    align-items: center;
    align-content: center; }
    .bg-item .bg-text label {
      padding-left: 10px; }
    .bg-item .bg-text label::before {
      top: 0;
      width: 1.40rem;
      height: 1.40rem;
      border: 1px #D0D0D3 solid;
      border-radius: 0.25rem; }
    .bg-item .bg-text label::after {
      top: 0;
      width: 1.40rem;
      height: 1.40rem;
      border: 1px #D0D0D3 solid;
      border-radius: 0.25rem; }

.request-payment-wrapper {
  width: 100%;
  margin-bottom: 50px !important; }
  .request-payment-wrapper .request-paymen-heading {
    display: block;
    padding: 32px; }
  .request-payment-wrapper .request-payment-area {
    display: flex; }
    @media screen and (max-width: 960px) {
      .request-payment-wrapper .request-payment-area {
        flex-direction: column; } }
  .request-payment-wrapper .request-payment-selector {
    flex: 1 1;
    display: flex;
    padding: 0 32px; }
    .request-payment-wrapper .request-payment-selector .button-selector {
      flex: 1 1;
      margin-right: 16px;
      height: 200px;
      background: #3F4448;
      border: 0;
      border-radius: 4px;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
      padding: 16px; }
      .request-payment-wrapper .request-payment-selector .button-selector:last-child {
        margin-right: 0; }
      .request-payment-wrapper .request-payment-selector .button-selector.active {
        background: #F25037; }
  .request-payment-wrapper .request-payment-box {
    flex: 1 0;
    padding: 0 48px;
    display: flex;
    flex-direction: column; }
    @media screen and (max-width: 960px) {
      .request-payment-wrapper .request-payment-box {
        margin-top: 50px; } }
    .request-payment-wrapper .request-payment-box .request-info {
      flex-grow: 1;
      display: flex; }
      @media screen and (max-width: 960px) {
        .request-payment-wrapper .request-payment-box .request-info {
          margin-bottom: 50px; } }
      .request-payment-wrapper .request-payment-box .request-info > div {
        flex: 1 1;
        display: flex;
        align-items: center; }
        .request-payment-wrapper .request-payment-box .request-info > div:first-child {
          flex-direction: column;
          justify-content: center;
          align-items: flex-start; }
        .request-payment-wrapper .request-payment-box .request-info > div:last-child {
          flex: 60px 0; }
    .request-payment-wrapper .request-payment-box .disclaimer-flow {
      text-align: center; }
    .request-payment-wrapper .request-payment-box a:hover {
      cursor: pointer !important; }
    .request-payment-wrapper .request-payment-box button {
      font-size: 1.5em !important; }

.sbitem {
  display: flex;
  flex-direction: column;
  border-left: 1px #D0D0D3 solid;
  border-top: 1px #D0D0D3 solid;
  border-right: 1px #D0D0D3 solid;
  background: #fff; }
  .sbitem .sbinput {
    height: 100%;
    width: 100%;
    border: 0;
    box-sizing: content-box; }
    .sbitem .sbinput .form-control, .sbitem .sbinput .input-group-prepend {
      height: 100%; }
    .sbitem .sbinput .form-control {
      border: 0;
      color: #637282; }
    .sbitem .sbinput button {
      background-color: transparent;
      border: 0;
      height: 100%; }
    .sbitem .sbinput svg {
      color: #D0D0D3;
      width: 20px !important;
      height: 100% !important;
      margin: 0; }
    .sbitem .sbinput .input-group {
      align-items: center;
      height: 60px; }
  .sbitem .sblist {
    flex: 200px 0;
    height: 200px;
    overflow-y: auto; }
  .sbitem .sbnoresults {
    display: flex;
    flex-direction: column;
    flex: 200px 0;
    border: 1px #D0D0D3 solid;
    height: 200px;
    align-items: center;
    justify-content: center; }
    .sbitem .sbnoresults button {
      margin-top: 16px; }
  .sbitem .sbbuttonsarea {
    padding: 16px; }
  .sbitem .sblistbuttons {
    margin: 8px; }
    .sbitem .sblistbuttons button {
      margin-left: 8px; }
    .sbitem .sblistbuttons .biitem {
      margin-left: 0px;
      margin-right: 8px;
      margin-bottom: 8px;
      padding: 4px;
      border-radius: 50px;
      display: flex;
      background: #D0D0D3;
      overflow: hidden; }
      .sbitem .sblistbuttons .biitem .biitem-info {
        color: #3F4448;
        padding: 0 4px; }
      .sbitem .sblistbuttons .biitem .biitem-icon {
        background: #637282;
        color: #D0D0D3;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center; }
        .sbitem .sblistbuttons .biitem .biitem-icon:hover {
          background: #3F4448;
          color: #fff; }

.text-result {
  margin-bottom: 8px; }

.biitem-danger {
  background: #F25037;
  border-color: #F25037;
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: #fff;
  max-width: 300px;
  margin-right: 8px; }
  .biitem-danger svg {
    color: #fff;
    width: 14px !important;
    height: 100% !important; }
  .biitem-danger:hover {
    background: #DF432B;
    border-color: #DF432B; }

.textarea {
  flex-grow: 1;
  margin-left: 24px; }

.iconarea {
  background: #DF432B;
  display: flex;
  flex: 40px 0;
  height: 100%;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }

.tbitem {
  border-bottom: 1px #D0D0D3 solid;
  width: 100%; }

.steps-hero-wrapper {
  width: 100%;
  display: flex; }
  .steps-hero-wrapper .steps-items {
    flex: 1 1;
    display: flex;
    margin-right: 8px; }
    .steps-hero-wrapper .steps-items:last-child {
      margin-right: 0; }
    .steps-hero-wrapper .steps-items .steps-item {
      flex: 1 1;
      padding: 8px; }
      .steps-hero-wrapper .steps-items .steps-item:first-child {
        flex: 60px 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: flex-start; }
      .steps-hero-wrapper .steps-items .steps-item .steps-icon {
        height: auto;
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 900;
        font-size: 1.5rem;
        overflow: hidden; }
        .steps-hero-wrapper .steps-items .steps-item .steps-icon img {
          width: 80px;
          height: auto; }

/*@media (min-width: 576px){
    .container {
        max-width: calc(540px - 100px);
    };
};

@media (min-width: 768px) {
    .container {
        max-width: calc(720px - 200px);
    };
};

@media (min-width: 992px) {
    .container {
        max-width: calc(960px - 200px);
    };
};

@media (min-width: 1200px){
    .container {
        max-width: calc(1200px - 200px);
    };
};*/
.client-wrapper {
  height: 500px;
  display: flex;
  flex-direction: column; }
  .client-wrapper.related-wrapper {
    height: 400px; }
    .client-wrapper.related-wrapper .client-title-wrapper {
      flex: 110px 0;
      display: flex; }
  .client-wrapper .client-title-wrapper {
    flex: 200px 0;
    display: flex;
    align-items: center;
    justify-content: center; }
  .client-wrapper .client-slider-wrapper {
    flex: 1 1; }
    .client-wrapper .client-slider-wrapper .swiper-slide-active {
      background: #F25037;
      color: #fff; }

.swiper-container {
  height: 100%;
  display: flex; }
  .swiper-container .swiper-wrapper .swiper-slide .container {
    height: 100%; }

.slider-item {
  display: flex; }
  .slider-item .slider-image {
    flex: 200px 0; }
    .slider-item .slider-image img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .slider-item .slider-info {
    flex: 1 1;
    padding: 32px;
    display: flex;
    justify-content: center;
    flex-direction: column; }

.area-wrapper-content {
  height: 85%;
  display: flex;
  flex-direction: column; }
  @media screen and (max-width: 1200px) {
    .area-wrapper-content {
      height: auto; } }
  .area-wrapper-content .swiper-container-horizontal .swiper-pagination-bullets {
    bottom: -5px; }
    @media screen and (min-width: 720px) {
      .area-wrapper-content .swiper-container-horizontal .swiper-pagination-bullets {
        display: none; } }
  .area-wrapper-content .swiper-slides-full {
    flex: 1 1; }
    @media screen and (max-width: 720px) {
      .area-wrapper-content .swiper-slides-full .wrapper-content-slide .container {
        flex-direction: column; } }
    .area-wrapper-content .swiper-slides-full .wrapper-content-slide .area-item .image-wrapper {
      width: 100%;
      height: auto; }
    @media screen and (max-width: 720px) {
      .area-wrapper-content .swiper-slides-full .wrapper-content-slide .area-item .content-wrapper {
        width: 90%; } }
    .area-wrapper-content .swiper-slides-full .wrapper-content-slide h1 {
      font-size: 1.8rem; }
  .area-wrapper-content .swiper-slides-thumbs {
    flex: 50px 0;
    background: #f6f5f8;
    padding: 18px 0; }
    @media screen and (max-width: 720px) {
      .area-wrapper-content .swiper-slides-thumbs {
        display: none; } }
    @media screen and (max-width: 720px) {
      .area-wrapper-content .swiper-slides-thumbs .wrapper-content-slide .container {
        flex-direction: column; } }

.wrapper-content-slide {
  display: flex; }
  .wrapper-content-slide.wrapper-content-block {
    height: 80%; }
  .wrapper-content-slide .container {
    height: 100%;
    display: flex; }
  .wrapper-content-slide:nth-child(even) .area-item.thumbs:first-child {
    order: 2; }
  .wrapper-content-slide:nth-child(even) .area-item.thumbs:last-child {
    order: 2; }
  .wrapper-content-slide:nth-child(even) .area-item:first-child {
    order: 2; }
  .wrapper-content-slide:nth-child(even) .area-item:last-child {
    order: 1; }
  .wrapper-content-slide .area-item {
    flex: 1 1;
    padding: 16px;
    display: flex; }
    .wrapper-content-slide .area-item:first-child {
      align-items: center; }
    .wrapper-content-slide .area-item:last-child {
      align-items: center; }
    .wrapper-content-slide .area-item.thumbs {
      cursor: pointer;
      background: white; }
      .wrapper-content-slide .area-item.thumbs:first-child {
        flex: 100px 0;
        padding: 0; }
        .wrapper-content-slide .area-item.thumbs:first-child .image-wrapper {
          width: 100%;
          height: 100%; }
    .wrapper-content-slide .area-item .content-wrapper {
      width: 70%; }
    .wrapper-content-slide .area-item .image-wrapper {
      width: 80%;
      height: 80%;
      overflow: hidden; }
      .wrapper-content-slide .area-item .image-wrapper img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .wrapper-content-slide .area-item:first-child {
      order: 1; }
    .wrapper-content-slide .area-item:last-child {
      order: 2;
      justify-content: center;
      flex-direction: column; }

.suggested-wrapper, .profile-wrapper {
  height: 225px;
  display: flex;
  background: #24292E;
  margin-bottom: 50px;
  color: #fff; }
  .suggested-wrapper .suggested-wrapper-area, .suggested-wrapper .profile-wrapper-area, .profile-wrapper .suggested-wrapper-area, .profile-wrapper .profile-wrapper-area {
    display: flex;
    height: 100%; }
  .suggested-wrapper .suggested-area-left, .suggested-wrapper .suggested-area-right, .suggested-wrapper .profile-area-left, .suggested-wrapper .profile-area-right, .profile-wrapper .suggested-area-left, .profile-wrapper .suggested-area-right, .profile-wrapper .profile-area-left, .profile-wrapper .profile-area-right {
    display: flex;
    flex: 1 1;
    align-items: center; }
  .suggested-wrapper .suggested-area-right, .suggested-wrapper .profile-area-right, .profile-wrapper .suggested-area-right, .profile-wrapper .profile-area-right {
    justify-content: flex-end; }
  .suggested-wrapper .suggested-area-left .item, .suggested-wrapper .profile-area-left .item, .profile-wrapper .suggested-area-left .item, .profile-wrapper .profile-area-left .item {
    flex: 180px 0; }
    .suggested-wrapper .suggested-area-left .item:last-child, .suggested-wrapper .profile-area-left .item:last-child, .profile-wrapper .suggested-area-left .item:last-child, .profile-wrapper .profile-area-left .item:last-child {
      flex: 1 1;
      display: flex;
      flex-direction: column; }

.cbitem {
  display: flex;
  flex-direction: column;
  margin: 40px; }
  .cbitem .cbinput {
    height: 100%;
    width: 100%;
    box-sizing: content-box; }
    .cbitem .cbinput .form-control {
      border: 0;
      color: #637282; }
    .cbitem .cbinput button {
      border: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 1px #D0D0D3 solid; }
    .cbitem .cbinput .input-group {
      align-items: center;
      border: 1px #D0D0D3 solid;
      border-radius: 4px;
      display: flex; }

.maar-item {
  display: flex;
  min-height: calc(40px + 8px);
  width: 100%;
  flex-direction: column; }
  .maar-item .maar-text {
    flex: 1 1;
    display: flex;
    padding: 8px;
    align-items: center;
    justify-content: center; }
  .maar-item label {
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 0; }
  .maar-item label::before {
    top: 0;
    width: 1.40rem;
    height: 1.40rem;
    border: 1px #D0D0D3 solid; }
  .maar-item label::after {
    top: 0;
    width: 1.40rem;
    height: 1.40rem;
    border: 1px #D0D0D3 solid; }
  .maar-item .maar-input {
    flex: 2 1;
    z-index: 0; }
    .maar-item .maar-input .form-group {
      height: 100%; }
      .maar-item .maar-input .form-group .input-group {
        height: 100%;
        display: flex;
        align-items: stretch; }
        .maar-item .maar-input .form-group .input-group .form-control {
          height: 100%;
          height: calc(100% - 0);
          flex: 1 1;
          padding: 0px 16px; }
        .maar-item .maar-input .form-group .input-group .input-group-prepend {
          height: 100%; }
          .maar-item .maar-input .form-group .input-group .input-group-prepend .btn {
            height: calc(100% + 2px); }
    .maar-item .maar-input .form-control {
      border: 1px #D0D0D3 solid;
      box-sizing: content-box;
      color: #637282; }
    .maar-item .maar-input button {
      border: 1px #D0D0D3 solid;
      height: 52px;
      z-index: 0; }
    .maar-item .maar-input svg {
      color: #D0D0D3;
      width: 20px !important;
      height: 100% !important;
      margin: 0; }
    .maar-item .maar-input input {
      border-radius: 0; }
  @media screen and (max-width: 720px) {
    .maar-item.search-without-params .maar-search {
      height: 60px !important; } }
  .maar-item .maar-search {
    display: flex;
    align-items: stretch;
    margin-bottom: 8px;
    height: 40px; }
    @media screen and (max-width: 720px) {
      .maar-item .maar-search {
        display: block !important;
        height: 160px !important; }
        .maar-item .maar-search .maar-select, .maar-item .maar-search .maar-checks, .maar-item .maar-search .maar-input {
          width: 100% !important;
          height: 50px !important;
          display: block !important; } }
    .maar-item .maar-search .maar-select {
      margin-right: 8px;
      flex: 1 1; }
      @media screen and (max-width: 720px) {
        .maar-item .maar-search .maar-select {
          margin-bottom: 8px; } }
      .maar-item .maar-search .maar-select .form-group {
        height: 100%;
        margin-bottom: 0; }
        .maar-item .maar-search .maar-select .form-group .form-control {
          height: 100%; }
    .maar-item .maar-search .maar-checks {
      flex: 1 1;
      display: flex; }
  .maar-item .maar-list .pagination-wrapper {
    overflow-x: auto; }
  .maar-item .maar-list .pagination {
    justify-content: center;
    margin: 30px; }
  .maar-item .maar-list .data-slice {
    margin-top: 15px; }
  .maar-item .alert_message {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px; }
  .maar-item .alert-custom {
    margin-left: 0;
    margin-right: 0;
    margin-top: 8px;
    width: inherit; }
  .maar-item .maar-alerts div:first-child {
    margin-top: 0; }
  .maar-item .maar-alerts div:last-child {
    margin-bottom: 8px; }

.input-tooltip {
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: 440px;
  height: 120px;
  background: #ffffff;
  z-index: 1060; }
  .input-tooltip .tooltip-wrapper {
    width: 100%;
    height: 100%;
    position: relative; }
  .input-tooltip .tooltip-close {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 40px;
    height: 40px;
    padding: 0px !important;
    margin: 0px !important; }
  .input-tooltip .tooltip-content {
    position: absolute;
    left: 25%;
    right: 32px;
    top: 12%;
    text-align: justify; }
    .input-tooltip .tooltip-content h4 {
      text-align: left;
      font-size: 1.28em;
      font-weight: 700;
      margin-bottom: 16px; }
    .input-tooltip .tooltip-content small {
      display: block;
      margin-bottom: 8px; }
    .input-tooltip .tooltip-content .widget-rank {
      padding: 8px 0; }
      .input-tooltip .tooltip-content .widget-rank .rank span {
        height: 26px; }
  .input-tooltip .tooltip-footer {
    position: absolute;
    bottom: 8px;
    right: 8px; }
    .input-tooltip .tooltip-footer a {
      font-size: 0.85em; }
  .input-tooltip .tooltip-logo {
    top: 8px;
    right: 8px;
    position: absolute;
    left: 5%;
    right: 80%;
    top: 15%; }
  .input-tooltip .tooltip-inner {
    color: #24292E;
    text-align: left; }

.TabComments-container {
  border: 0;
  display: flex;
  flex-direction: column;
  padding: 8px;
  background: #fff;
  border-bottom: 3px solid #e5e5e5;
  border-right: 1px solid #e5e5e5; }
  .TabComments-container .title-chat-area {
    height: auto;
    display: flex;
    align-items: center; }
  .TabComments-container .notify-buttonarea {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 16px; }

.TabComments-list {
  overflow-y: auto;
  max-height: 400px; }

.request-field {
  padding-bottom: 0.5em; }
  .request-field .form-group {
    background: #fff;
    margin-bottom: 16px;
    padding: 16px;
    border-bottom: 3px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    display: flex;
    flex-wrap: wrap;
    position: relative; }
    .request-field .form-group .col-md-12, .request-field .form-group .col-md-4, .request-field .form-group .col-md-10 {
      position: initial; }
    .request-field .form-group .span-date {
      position: absolute;
      right: 8px;
      bottom: 4px; }
      .request-field .form-group .span-date p {
        color: #637282; }
        .request-field .form-group .span-date p b {
          color: #24292E; }
  .request-field label {
    color: #637282;
    margin-bottom: 0; }
  .request-field .backgroundItemRemove {
    background: #cac7c7; }
  .request-field .label-title-field {
    text-overflow: ellipsis;
    word-wrap: break-word;
    width: 100%; }
  .request-field .request-field-comment {
    margin-top: 1em; }
    .request-field .request-field-comment p {
      color: #000000; }
  .request-field p {
    font-size: 0.85em;
    color: #637282;
    line-height: 14px; }
  .request-field span .text-help {
    color: #637282; }
  .request-field h1 {
    line-height: 29px;
    font-size: 1.714em;
    word-break: break-word;
    margin-bottom: 0.25em;
    margin-top: 0.25em; }
  .request-field .preview-image-wrap .preview-data div {
    white-space: unset;
    text-overflow: unset;
    word-break: break-all; }
  .request-field .preview-image-wrap {
    min-height: 200px; }

.file-upload-wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 4px; }
  .file-upload-wrapper .file-reason {
    display: flex; }
    .file-upload-wrapper .file-reason .file-reason-info {
      flex: 1 1; }
    .file-upload-wrapper .file-reason .file-reason-action {
      flex: 1 1;
      display: flex;
      align-items: center;
      justify-content: flex-end; }
  .file-upload-wrapper .file-preview {
    padding: 16px 0; }

.preview-ie {
  height: 100%;
  width: 100%; }

.preview-image-wrap {
  width: 200px;
  height: 200px;
  display: inline-flex;
  flex-direction: column;
  margin-right: 8px;
  margin-bottom: 8px;
  background: #eee;
  padding: 8px; }
  .preview-image-wrap:last-child {
    margin-right: 0; }
  .preview-image-wrap .pointer:hover {
    cursor: pointer; }
  .preview-image-wrap .preview-data {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center; }
    .preview-image-wrap .preview-data div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      width: 100%;
      height: 40px;
      min-height: 40px;
      max-height: 40px; }
  .preview-image-wrap .preview-image {
    border-radius: 4px;
    flex: 126px 0;
    overflow: hidden; }
    .preview-image-wrap .preview-image img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

.pagination {
  margin: 30px 0; }
  .pagination .page-item .page-link {
    color: #F25037; }
  .pagination .page-item.active .page-link {
    background: #F25037;
    color: #fff;
    border-color: #F25037; }

.bell-main {
  display: flex;
  width: 40px;
  height: 40px;
  position: relative;
  font-size: 1.5rem;
  justify-content: center;
  align-items: center; }
  .bell-main .circle {
    position: absolute;
    top: 60%;
    right: 10%;
    height: 10px;
    width: 10px;
    border-radius: 50%; }
    .bell-main .circle.assintence {
      background-color: #F25037; }
    .bell-main .circle.noassintence {
      background-color: transparent; }

.handleArea {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px; }
  .handleArea button {
    border: 0;
    background: black;
    color: white;
    padding: 10px 30px; }

.area-wrapper {
  height: 80%;
  display: flex; }
  .area-wrapper:nth-child(odd) {
    background: #f9f9f9; }
  .area-wrapper.video {
    background: #161616;
    background-size: cover;
    background-position: top;
    position: relative;
    display: flex;
    color: white;
    background-repeat: no-repeat; }
    .area-wrapper.video .container {
      position: relative;
      z-index: 1; }
    .area-wrapper.video:after {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      content: "";
      background: rgba(21, 21, 21, 0.5); }

.video-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column; }
  .video-wrapper .video-wrapper-item {
    flex: 1 1; }
    .video-wrapper .video-wrapper-item:first-child {
      display: flex;
      align-items: flex-end;
      justify-content: center; }
      .video-wrapper .video-wrapper-item:first-child .video-item-play {
        background: white;
        font-size: 1rem;
        border-radius: 50%;
        height: 80px;
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0; }
    .video-wrapper .video-wrapper-item:last-child {
      overflow: hidden; }
      .video-wrapper .video-wrapper-item:last-child h1 {
        font-size: 25rem;
        font-weight: bolder; }
        @media screen and (max-width: 720px) {
          .video-wrapper .video-wrapper-item:last-child h1 {
            font-size: 20rem; } }

.wrapper-draw {
  position: relative;
  width: 100%;
  max-width: 400px;
  height: 100%;
  max-height: 400px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-size: cover !important; }
  .wrapper-draw.center {
    margin: auto; }

.wrapper-content {
  display: flex;
  height: 100%; }

.wrapper-content-item {
  flex: 1 1;
  display: flex;
  align-items: center; }

.wrapper-content-card-info {
  display: block; }

.wrapper-content-card-icon {
  height: 40px;
  margin-bottom: 20px; }
  .wrapper-content-card-icon img {
    height: 100%; }

.wrapper-content-card-header {
  margin-bottom: 40px; }

.area-wrapper-category-list {
  display: block;
  width: 100%;
  height: auto;
  margin-top: 20px; }
  .area-wrapper-category-list .area-wrapper-title {
    display: flex;
    height: auto;
    flex-direction: column;
    padding: 16px 0; }
  .area-wrapper-category-list .area-wrapper-content {
    display: flex;
    width: 100%;
    height: auto;
    flex-wrap: wrap; }
    .area-wrapper-category-list .area-wrapper-content .category-item {
      border: 1px solid #D0D0D3;
      min-height: 80px;
      margin-bottom: 8px;
      display: flex;
      flex: calc(50% - 4px) 0;
      transition: 0.4s; }
      .area-wrapper-category-list .area-wrapper-content .category-item.ie {
        flex: 49% 0; }
      .area-wrapper-category-list .area-wrapper-content .category-item:hover {
        box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.5);
        cursor: pointer; }
      @media screen and (max-width: 720px) {
        .area-wrapper-category-list .area-wrapper-content .category-item {
          flex-direction: column; } }
      .area-wrapper-category-list .area-wrapper-content .category-item:nth-child(even) {
        margin-left: 8px; }
      .area-wrapper-category-list .area-wrapper-content .category-item .item-image {
        flex: 100px 0; }
        .area-wrapper-category-list .area-wrapper-content .category-item .item-image.ie {
          background-size: cover !important; }
        .area-wrapper-category-list .area-wrapper-content .category-item .item-image img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .area-wrapper-category-list .area-wrapper-content .category-item .item-content {
        flex: 1 1;
        padding: 8px; }

.area-wrapper-category {
  height: auto;
  display: flex;
  margin-top: 100px; }
  @media screen and (max-width: 720px) {
    .area-wrapper-category .title-wrapper-category {
      text-align: center; } }
  .area-wrapper-category .area-link {
    display: flex;
    align-items: center;
    justify-content: flex-end; }
    @media screen and (max-width: 720px) {
      .area-wrapper-category .area-link {
        justify-content: center; } }
  .area-wrapper-category .category-list {
    margin: 50px 0px; }
    .area-wrapper-category .category-list.column {
      flex-direction: column; }
    @media screen and (max-width: 720px) {
      .area-wrapper-category .category-list {
        flex-direction: column; } }
  .area-wrapper-category .category-item {
    margin-right: 8px;
    flex: 1 1;
    height: auto;
    transition: 0.4s; }
    .area-wrapper-category .category-item:last-child {
      margin-right: 0; }
    .area-wrapper-category .category-item:hover {
      box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.5);
      cursor: pointer; }
    .area-wrapper-category .category-item .category-item-header {
      height: 300px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .area-wrapper-category .category-item .category-item-header.ie {
        background-size: cover !important; }
      .area-wrapper-category .category-item .category-item-header img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .area-wrapper-category .category-item .category-item-content {
      padding: 25px; }
      .area-wrapper-category .category-item .category-item-content p {
        font-weight: bolder; }

.responsive-border-card-task {
  border-left: 1px solid #dee2e6; }
  @media screen and (max-width: 720px) {
    .responsive-border-card-task {
      border-left: 0;
      margin-top: 16px;
      padding-top: 16px; } }

.text-tags {
  color: #F25037; }

.heading-category {
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 16px; }
  .heading-category p {
    color: #637282; }

.new-footer {
  display: flex;
  justify-content: center; }
  .new-footer .btn {
    margin-left: 8px; }

.new-item {
  display: flex;
  min-height: calc(80px + 8px);
  flex-direction: column; }
  .new-item .new-avatar {
    flex: 70px 0;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer; }
  .new-item .new-content {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 8px;
    align-items: center; }
    .new-item .new-content div {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      flex: 1 1; }

.bottom-item {
  display: flex;
  height: auto;
  bottom: 16px;
  right: 16px;
  position: fixed;
  background: #fff;
  color: #24292E;
  border: 1px #D0D0D3 solid;
  width: 300px;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23); }
  .bottom-item .new-title {
    margin-bottom: 4px;
    background-color: #F25037;
    padding-left: 4px;
    color: #fff; }
  .bottom-item .new-body {
    display: flex;
    justify-content: center; }
  .bottom-item .new-action {
    flex: 1 1; }
  .bottom-item .new-avatar {
    flex: 70px 0;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer; }
  .bottom-item .new-content {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 8px;
    align-items: center; }
    .bottom-item .new-content div {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      flex: 1 1; }

.disponibility-wrapper {
  width: 100%;
  height: auto;
  display: block;
  border: 1px solid #e5e5e5; }

.schedule-wrapper {
  display: block;
  width: 100%;
  height: auto; }

.schedule-title {
  display: block;
  width: 100%;
  height: auto;
  background: #F8F8F9;
  padding: 16px; }

.schedule-content {
  display: flex;
  padding: 16px; }
  .schedule-content .schedule-content-area {
    flex: 1 1; }
    .schedule-content .schedule-content-area .schedule-location {
      padding: 16px;
      border: 1px solid #F8F8F9;
      border-radius: 4px;
      background: #F8F8F9; }
      .schedule-content .schedule-content-area .schedule-location small {
        display: block; }

.disponibility-action {
  display: flex;
  padding: 16px;
  justify-content: flex-end; }

.assistance-wrapper {
  display: block;
  width: 100%;
  height: auto; }
  .assistance-wrapper .assistance-header {
    display: flex;
    padding: 16px;
    background: #D0D0D3; }
    .assistance-wrapper .assistance-header div {
      flex: 2 1;
      display: flex;
      align-items: center; }
      .assistance-wrapper .assistance-header div:last-child {
        flex: 1 1;
        justify-content: flex-end; }
  .assistance-wrapper .assistance-content {
    display: block;
    width: 100%;
    height: auto; }

.assistance-wrapper-email {
  display: block;
  width: 100%;
  height: auto;
  padding: 16px; }
  .assistance-wrapper-email .assistance-title {
    display: block;
    width: 100%;
    height: auto; }
    .assistance-wrapper-email .assistance-title small {
      display: block; }
  .assistance-wrapper-email .assistance-content {
    display: block;
    width: 100%;
    height: auto; }

.simple-maps-wrapper {
  width: 96%;
  height: 300px; }

.sign-wrapper {
  width: 100%;
  height: auto;
  display: block;
  border-bottom: 3px solid #e5e5e5;
  border-right: 1px solid #e5e5e5; }

.sign-title {
  display: block;
  width: 100%;
  height: auto;
  background: #F8F8F9;
  padding: 16px;
  border-bottom: 1px solid #e5e5e5; }

.sign-content {
  padding: 16px;
  background: #ffffff; }

.account-unsubscribe {
  max-width: 460px;
  width: calc(100% - 40px);
  padding: 44px;
  margin: 0 auto;
  background: #fff;
  position: relative;
  z-index: 1; }
  .account-unsubscribe .account-unsunscribe-content {
    margin-top: 16px; }
    .account-unsubscribe .account-unsunscribe-content .buttons-row {
      margin-top: 16px;
      width: 100%;
      display: flex; }
      .account-unsubscribe .account-unsunscribe-content .buttons-row button {
        flex: 1 1;
        margin: 0;
        height: 50px; }
        .account-unsubscribe .account-unsunscribe-content .buttons-row button:first-child {
          margin-right: 8px; }
        .account-unsubscribe .account-unsunscribe-content .buttons-row button:last-child {
          margin-left: 8px; }
          .account-unsubscribe .account-unsunscribe-content .buttons-row button:last-child:hover {
            border-color: #D0D0D3;
            color: #ffffff; }

.stepper-wrapper {
  display: flex;
  flex-direction: column; }
  @media screen and (max-width: 720px) {
    .stepper-wrapper {
      flex: 1 1; } }
  .stepper-wrapper .stepper-wraper-icons {
    flex: 80px 0;
    display: flex;
    position: relative; }
    .stepper-wrapper .stepper-wraper-icons .stepper-block {
      display: flex;
      flex: 1 1;
      border-bottom: 1px solid #D0D0D3;
      position: relative;
      color: #D0D0D3; }
      @media screen and (max-width: 720px) {
        .stepper-wrapper .stepper-wraper-icons .stepper-block:last-child {
          border: 0;
          flex: 20px 0; } }
      .stepper-wrapper .stepper-wraper-icons .stepper-block .stepper-number, .stepper-wrapper .stepper-wraper-icons .stepper-block .stepper-text {
        display: flex;
        align-items: center; }
        @media screen and (max-width: 720px) {
          .stepper-wrapper .stepper-wraper-icons .stepper-block .stepper-number, .stepper-wrapper .stepper-wraper-icons .stepper-block .stepper-text {
            display: none; } }
      .stepper-wrapper .stepper-wraper-icons .stepper-block .stepper-number {
        flex: 50px 0; }
      .stepper-wrapper .stepper-wraper-icons .stepper-block .stepper-text {
        flex: 1 1; }
        .stepper-wrapper .stepper-wraper-icons .stepper-block .stepper-text small {
          min-width: 65px;
          margin-left: 10px; }
      .stepper-wrapper .stepper-wraper-icons .stepper-block .stepper-circle {
        background: #D0D0D3;
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0;
        bottom: -10px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8px;
        color: #fff; }
      .stepper-wrapper .stepper-wraper-icons .stepper-block.active {
        border-color: #89E051; }
        .stepper-wrapper .stepper-wraper-icons .stepper-block.active .stepper-number {
          color: #fff; }
        .stepper-wrapper .stepper-wraper-icons .stepper-block.active .stepper-circle {
          background: #89E051; }
  .stepper-wrapper .stepper-wraper-info {
    padding: 8px 0;
    flex: 1 1;
    margin: 8px 0;
    color: #24292E;
    max-width: 400px; }
    .stepper-wrapper .stepper-wraper-info p {
      color: #fff; }

html {
  height: 100%; }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "LatoWeb", "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  height: 100%;
  overflow: auto; }

p, h1, h2, h3, h4, h5, h6 {
  margin: 0; }

#root {
  height: 100%; }

* {
  outline: none; }

main {
  height: 100%; }
  main.fullscreen {
    height: 100%;
    background: url(../images/hero.jpg) center center no-repeat;
    background-size: cover;
    position: relative; }
    main.fullscreen:after {
      content: "";
      position: absolute;
      background: rgba(0, 0, 0, 0.6);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
  main.centered {
    display: flex;
    align-items: center; }

main {
  width: 100%; }

footer {
  background: #000000;
  color: #ffffff;
  padding: 32px 0 16px 0; }
  footer .footer-wrapper-menu {
    margin-bottom: 64px; }
    footer .footer-wrapper-menu .row:first-child {
      margin-bottom: 8px; }
  footer ul.nav li a {
    color: inherit;
    padding-left: 0; }
    footer ul.nav li a:hover {
      text-decoration: underline; }

.footer-inline-nav {
  justify-content: flex-end; }

.footer-nav-geo {
  font-size: 1.5rem; }
  .footer-nav-geo li {
    text-decoration: none; }
    .footer-nav-geo li a {
      display: flex;
      align-items: center;
      height: 50px; }
      .footer-nav-geo li a svg {
        flex: 40px 0; }
      .footer-nav-geo li a span {
        flex: 1 1; }
      .footer-nav-geo li a.nav-no-link:hover {
        cursor: initial;
        text-decoration: none; }

.dynamic-footer {
  background: #000000;
  color: #ffffff;
  padding: 64px 0 64px 0;
  height: auto;
  width: 100%; }
  .dynamic-footer .contact-link {
    color: #fff;
    margin-top: 16px !important; }
    .dynamic-footer .contact-link a {
      color: #fff; }

.wrapper-area {
  min-height: calc(400px + 8px);
  background: #eeeeee;
  padding-bottom: 50px; }

.section-wrapper {
  margin-bottom: 50px; }

.hero {
  height: 90vh;
  display: flex;
  flex-direction: column;
  background: url(../images/hero.jpg) center center no-repeat;
  background-size: cover;
  position: relative;
  color: #fff; }
  .hero:after {
    content: "";
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .hero .container {
    flex: 3 1;
    display: flex;
    align-items: flex-end;
    position: relative;
    z-index: 1; }
    .hero .container:last-child {
      flex: 1 1;
      display: flex;
      align-items: center; }
    .hero .container:first-child {
      z-index: 2; }

.card-hover {
  transition: .4s; }
  .card-hover:hover {
    background-color: rgba(0, 0, 0, 0.03);
    border: 1px solid #F25037;
    box-shadow: 0 0 2px 0 rgba(19, 26, 31, 0.12), 0 2x 4px 0 rgba(19, 26, 31, 0.22) !important; }

.tips-wrapper {
  display: flex;
  height: 150px;
  margin-top: 50px; }
  .tips-wrapper .tips-item {
    flex: 1 1; }
    .tips-wrapper .tips-item .card {
      background: #F25037;
      color: #fff; }
      .tips-wrapper .tips-item .card.purple {
        background: #773ce2; }
      .tips-wrapper .tips-item .card.blue {
        background: #3f51b5; }
      .tips-wrapper .tips-item .card.bluelight {
        background: #2196f3; }
      .tips-wrapper .tips-item .card.green {
        background: #009788; }
      .tips-wrapper .tips-item .card .icon-card {
        display: block;
        width: 100%;
        height: auto; }
    .tips-wrapper .tips-item:first-child {
      margin-right: 4px; }
    .tips-wrapper .tips-item:last-child {
      margin-left: 4px;
      margin-right: 0; }

.discover-wrapper-ie {
  height: 50vh;
  display: flex;
  align-items: center;
  margin: 100px 0px 100px 0px; }
  @media screen and (max-width: 720px) {
    .discover-wrapper-ie {
      height: auto; } }
  .discover-wrapper-ie .container {
    -ms-display: flex !important;
    display: flex;
    flex-wrap: wrap;
    height: 100%; }
    @media screen and (max-width: 720px) {
      .discover-wrapper-ie .container {
        flex-direction: column;
        height: auto; } }
    .discover-wrapper-ie .container .discover-item {
      flex: 500px 0;
      position: relative; }
      @media screen and (max-width: 720px) {
        .discover-wrapper-ie .container .discover-item {
          flex: 400px 0; } }
      .discover-wrapper-ie .container .discover-item .image-position-top {
        position: absolute;
        top: 0;
        left: 0;
        max-width: 300px; }
      .discover-wrapper-ie .container .discover-item .image-position-bottom {
        position: absolute;
        top: 8rem;
        left: 150px;
        max-width: 350px; }
    .discover-wrapper-ie .container .discover-item:nth-child(2) {
      flex: 1 1; }
    .discover-wrapper-ie .container .dicover-title {
      margin-top: 16px;
      margin-left: -150px; }
      @media screen and (max-width: 720px) {
        .discover-wrapper-ie .container .dicover-title {
          margin-left: 0; } }
    .discover-wrapper-ie .container .discover-info {
      padding-left: 8px;
      padding-top: 16px; }

.discover-wrapper {
  height: 80vh;
  display: flex;
  align-items: center;
  margin: 100px 0 80px 0; }
  @media screen and (max-width: 720px) {
    .discover-wrapper {
      margin-bottom: 0;
      height: auto; } }
  .discover-wrapper .container {
    display: grid;
    grid-template-columns: repeat(4, minmax(auto, 150px)) 300px 1fr;
    grid-template-rows: repeat(2, minmax(auto, 150px)) 1fr;
    height: 100%; }
    @media screen and (max-width: 960px) {
      .discover-wrapper .container {
        grid-template-rows: repeat(4, minmax(auto, 150px)) 1fr;
        grid-template-columns: repeat(3, minmax(auto, 150px));
        height: auto;
        max-width: 100%; } }
    .discover-wrapper .container div img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .discover-wrapper .image-thumb-top {
    grid-row: 1 / 3;
    grid-column: 1 / 3; }
  .discover-wrapper .image-thumb-bottom {
    grid-row: 2 / 4;
    grid-column: 2 / 5; }
  .discover-wrapper .discover-title {
    grid-row: 1 / 2;
    grid-column: 3 / 6;
    display: flex;
    justify-content: center;
    padding: 32px;
    flex-direction: column; }
    .discover-wrapper .discover-title h1 {
      font-weight: 900;
      font-size: 3rem; }
    @media screen and (max-width: 720px) {
      .discover-wrapper .discover-title {
        grid-row: 4 / 5;
        grid-column: 1 / 7; }
        .discover-wrapper .discover-title h1 {
          font-weight: 900;
          font-size: 2rem; } }
  .discover-wrapper .discover-area {
    grid-row: 2 / 5;
    grid-column: 5 / 7;
    padding: 32px; }
    @media screen and (max-width: 720px) {
      .discover-wrapper .discover-area {
        grid-row: 5 / 6;
        grid-column: 1 / 6; } }
    .discover-wrapper .discover-area h4 {
      margin-bottom: 32px; }

.checkout-wrapper {
  height: 40vh;
  background: #24292E;
  color: #ffffff;
  display: flex; }
  .checkout-wrapper .container {
    display: flex; }
    @media screen and (max-width: 1200px) {
      .checkout-wrapper .container {
        flex-direction: column; } }
    .checkout-wrapper .container .checkout-wrapper-item {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      flex: 1 1;
      flex-direction: column;
      position: relative; }
      .checkout-wrapper .container .checkout-wrapper-item:first-child {
        align-items: flex-start;
        padding-bottom: 32px; }
      .checkout-wrapper .container .checkout-wrapper-item .card {
        width: 400px;
        margin: 0 auto;
        background: #fff;
        color: #24292E;
        border-radius: 4px;
        height: 200px;
        display: flex;
        flex-direction: row;
        position: absolute;
        bottom: -100px;
        border: 0; }
        @media screen and (max-width: 1200px) {
          .checkout-wrapper .container .checkout-wrapper-item .card {
            margin: 0 auto;
            left: 0;
            right: 0; } }
        .checkout-wrapper .container .checkout-wrapper-item .card .card-item {
          flex: 1 1;
          display: flex;
          justify-content: center;
          position: relative;
          flex-direction: column; }
          .checkout-wrapper .container .checkout-wrapper-item .card .card-item:first-child {
            flex: 150px 0;
            align-items: stretch; }
            .checkout-wrapper .container .checkout-wrapper-item .card .card-item:first-child .avatar {
              width: 100%;
              height: 100%;
              border-radius: 0; }
          .checkout-wrapper .container .checkout-wrapper-item .card .card-item:last-child {
            padding: 16px;
            padding-bottom: 40px; }
            .checkout-wrapper .container .checkout-wrapper-item .card .card-item:last-child .icon {
              width: 40px;
              height: 40px;
              position: absolute;
              right: 16px;
              bottom: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #89E051; }
              .checkout-wrapper .container .checkout-wrapper-item .card .card-item:last-child .icon svg {
                width: 100%;
                height: 100%; }

.back-wrapper {
  background: #fff;
  padding: 32px 0;
  display: flex; }
  @media screen and (max-width: 1200px) {
    .back-wrapper {
      padding-top: 150px; } }
  .back-wrapper .container {
    display: flex; }
    @media screen and (max-width: 1200px) {
      .back-wrapper .container {
        flex-direction: column; } }
    .back-wrapper .container .checkout-wrapper-item {
      flex: 1 1; }
      .back-wrapper .container .checkout-wrapper-item h5, .back-wrapper .container .checkout-wrapper-item h6, .back-wrapper .container .checkout-wrapper-item button {
        margin-bottom: 16px; }

.access-wrapper {
  height: 100vh;
  display: flex; }
  .access-wrapper aside {
    flex: 1 1; }

.modal-video.modal-video-external .modal-content {
  height: auto; }

.modal-video .modal-content {
  height: 70vh;
  position: relative; }
  .modal-video .modal-content .logo-brand {
    position: absolute;
    left: 15px;
    top: 15px; }
  .modal-video .modal-content .close-modal-video {
    position: absolute;
    top: -50px;
    right: 0; }
  .modal-video .modal-content video {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .modal-video .modal-content .controls-wrapper {
    background: rgba(0, 0, 0, 0.89);
    color: #fff;
    height: 20%;
    position: absolute;
    bottom: 0;
    left: 0px;
    right: 0px;
    display: flex; }
    .modal-video .modal-content .controls-wrapper .control-item {
      flex: 1 1;
      padding: 8px 32px;
      display: flex;
      flex-direction: column; }
      .modal-video .modal-content .controls-wrapper .control-item:first-child {
        flex: 50px 0; }
      .modal-video .modal-content .controls-wrapper .control-item .control {
        flex: 2 1;
        display: flex;
        align-items: center; }
      .modal-video .modal-content .controls-wrapper .control-item .info {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        justify-content: center; }

.control-icon, .close-modal-video {
  background: transparent;
  border: 0;
  color: #fff;
  font-size: 1.5rem; }
  .control-icon:focus, .close-modal-video:focus {
    outline: 0; }

.bg-success {
  background: #89E051 !important;
  color: #ffffff !important; }

.bg-dark {
  background: #24292E !important;
  color: #ffffff !important; }

.text-success {
  color: #89E051 !important; }

.align-items-center {
  align-items: center; }

.badge {
  border-radius: 4px; }

.badge-label {
  margin-top: 8px; }
  .badge-label span {
    font-size: 1em !important;
    color: #fff !important;
    padding: 0.25em 1.5em !important; }
  .badge-label .badge-primary {
    background-color: #AD00FF; }

.badge-danger {
  background: #F25037; }

.margin-top {
  margin-top: 32px; }

.margin-bottom {
  margin-bottom: 32px; }

.col-4 {
  padding-left: 0; }

.clickeable {
  cursor: pointer; }

.error-type {
  display: flex;
  padding: 16px 0;
  min-height: 600px;
  align-items: center;
  background: #24292E;
  color: #E5E5E5; }
  .error-type .container {
    display: flex;
    flex-direction: column; }
    .error-type .container .error-item {
      flex: 1 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column; }
      .error-type .container .error-item:first-child {
        margin-bottom: 16px; }

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #F25037;
  background-color: #F25037; }

.content-body {
  position: relative;
  min-height: 600px; }

.min-height {
  min-height: 218px; }

.text-mutted {
  color: #637282; }

.badge-plus {
  background-color: #AD00FF;
  color: #fff; }

.text-plus {
  color: #AD00FF; }

.badge-price {
  font-size: 0.8rem; }

.bg-contact {
  background-color: #3F4448;
  color: #fff; }

.box-contact a {
  color: #fff;
  text-decoration: underline; }

select.form-control.is-invalid {
  -webkit-appearance: none; }

label.label.required {
  position: relative;
  display: flex;
  align-items: center; }
  label.label.required:after {
    content: "*";
    color: red;
    margin-left: 4px;
    font-weight: bolder; }

.background-note {
  background: yellow; }

hr.subtitle {
  margin-top: 0; }

hr.primary-main {
  border-color: #F25037; }
