@import '../../resources/scss/vars.scss';
img {
  object-fit: cover;
  width: 100%;
  height: 100%;
};
.card {
  border: none !important;
}
.card-img-overlay {
  position: absolute;
  top: 70% ;
  right: 0;
  bottom: 20%;
  left: 0;
  padding: 1.25rem;
}