@import './vars.scss';

.request-field {
  padding-bottom: 0.5em;
  .form-group {
    background: $theme-palette-primary-contrastText;
    margin-bottom: $theme-spacing-unit * 2;
    padding: $theme-spacing-unit *2;
    border-bottom: 3px solid $theme-palette-border-gray;
    border-right: 1px solid $theme-palette-border-gray;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    .col-md-12, .col-md-4, .col-md-10 {
      position: initial;
    };
    .span-date{ 
      position: absolute;
      right: $theme-spacing-unit;
      bottom: $theme-spacing-unit / 2;
      p {
        color: $theme-palette-grey-main;
        b {
          color: $theme-palette-dark-main;
        };
      };
    };
  };
  label {
    color: $theme-palette-grey-main;
    margin-bottom: 0;
  }

  .backgroundItemRemove{
    background: $theme-palette-danger-contrastText;
  }

  .label-title-field{
    text-overflow: ellipsis;
    word-wrap: break-word;
    width: 100%;
  }

  .request-field-comment {
    margin-top: 1em;

    p {
      color: $theme-palette-black;
    }
  }

  p {
    font-size: 0.85em;
    color: $theme-palette-grey-main;
    line-height: 14px;
  }
  span {
    .text-help {
      color: $theme-palette-grey-main;
    }
  }
  h1 {
    line-height: 29px;
    font-size: 1.714em;
    word-break: break-word;
    margin-bottom: 0.25em;
    margin-top: 0.25em;
  }
  .preview-image-wrap .preview-data div {
    white-space: unset;
    text-overflow: unset;
    word-break: break-all;
  }

  .preview-image-wrap {
    min-height: 200px;
  }
}
