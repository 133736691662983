@import "./vars.scss";
.bell-main {
  display: flex;
  width: 40px;
  height: 40px;
  position: relative;
  font-size: 1.5rem;
  justify-content: center;
  align-items: center;
  .circle {
    position: absolute;
    top: 60%;
    right: 10%;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    &.assintence{
        background-color: $theme-palette-primary-main;
    }
    &.noassintence{
        background-color: transparent;
    }
  }
}