@import './vars.scss';
.handleArea {
    display: flex; 
    align-items: center;
    justify-content: center;
    padding:20px;
    button {
        border: 0;
        background: black;
        color: white;
        padding: 10px 30px;
    };
};
.area-wrapper {
    height: 80%;
    display: flex;
    &:nth-child(odd){
        background: #f9f9f9;
    };
    &.video {
        background: #161616;
        background-size: cover;
        background-position: top;
        //background-size: cover!important;
        position: relative;
        display:flex;
        color: white;
        background-repeat: no-repeat;
        .container {
            position: relative;
            z-index: 1;
        };
        &:after {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            content:"";
            background: rgba(21,21,21,.5);
        };
    };
};

.video-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    .video-wrapper-item {
        flex: 1 1;
        &:first-child {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            .video-item-play {
                background: white;
                font-size: 1rem;
                border-radius: 50%;
                height: 80px;
                width: 80px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 0;
            }
        }

        &:last-child {
            overflow: hidden;
            h1 {
                font-size: 25rem;
                font-weight: bolder;
                @media screen and (max-width: $md) {
                    font-size: 20rem;
                }
            }
        }
    }
}

.wrapper-draw{
    position: relative;
    width: 100%;
    max-width: 400px;
    height: 100%;
    max-height: 400px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-size: cover!important;
    &.center {
        margin : auto;
    };
};
.wrapper-content {
    display: flex;
    height: 100%;
}
.wrapper-content-item {
    flex: 1 1;
    display:flex;
    align-items: center;
}
.wrapper-content-card-info {
    display: block;
}
.wrapper-content-card-icon {
    height: 40px;
    margin-bottom: 20px;
    img {
        height: 100%;
    }
}
.wrapper-content-card-header {
    margin-bottom: 40px;
}

.area-wrapper-category-list {
    display:block;
    width: 100%;
    height: auto;
    margin-top: 20px;
    .area-wrapper-title {
        display:flex;
        height: auto;
        flex-direction: column;
        padding: $theme-spacing-unit * 2 0;
    }
    .area-wrapper-content{
        display:flex;
        width: 100%;
        height: auto;
        flex-wrap: wrap;
        .category-item {
            border: 1px solid $theme-palette-grey-muted;
            min-height: 80px;
            margin-bottom: $theme-spacing-unit;
            display:flex;
            flex: calc(50% - 4px) 0;
            transition: 0.4s;
            &.ie {
                flex: 49% 0;
            }
            &:hover {
                box-shadow: 2px 2px 15px rgba(0,0,0,0.5);
                cursor: pointer;
            }
            @media screen and (max-width: $md) {
                flex-direction: column;
            };
            &:nth-child(even){
                margin-left: 8px;
            }
            .item-image {
                flex: 100px 0;
                &.ie {
                    background-size: cover!important;
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                };
            };
            .item-content {
                flex: 1 1;
                padding: $theme-spacing-unit;
            }
        };
    };
};

.area-wrapper-category {
    height: auto;
    display: flex;
    margin-top: 100px;
    .title-wrapper-category {
        @media screen and (max-width: $md) {
            text-align: center;
        };
    }
    .area-link {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @media screen and (max-width: $md) {
            justify-content: center;
        };
    };
    .category-list {
        margin: 50px 0px;
        &.column {
            flex-direction: column;
        }
        @media screen and (max-width: $md) {
            flex-direction: column;
        };
    };
    .category-item {
        margin-right: 8px;
        flex: 1 1;
        height: auto;
        transition: 0.4s;
        &:last-child {
            margin-right: 0;
        }
        &:hover {
            box-shadow: 2px 2px 15px rgba(0,0,0,0.5);
            cursor: pointer;
        }
        .category-item-header {
            height: 300px;
            display:flex;
            align-items: center;
            justify-content: center;
            &.ie {
                background-size: cover!important;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            };
        };
        .category-item-content {
            padding: 25px;
            p {
                font-weight: bolder;
            }
        }
    };
};
