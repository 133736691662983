@import './vars.scss';
.header {
    &.home {
        background: #1b1b1b;
        height:90%;
        align-items: stretch;
        display:flex;
        position:relative;
        color: $theme-palette-primary-contrastText;
        @media screen and (max-width: $md) {
            flex-direction: column;
            min-height: 90%;
        };
        .header-left {
            flex: 50% 1;
            position:relative;
            display:flex;
            flex-direction: column;
            padding: 0 50px;
            order: 1;
            @media screen and (max-width: $md) {
                padding: 0 20px;
                flex: 120px 0;
                order: 2;
                .header-item-left {
                    &:first-child {
                        display:none;
                    }
                    &:last-child {
                        display:none;
                    }
                }
            };
            .header-item-left {
                flex: 2 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                &:first-child {
                    flex: 1 1;
                };
                &:last-child {
                    flex: 1 1;
                };
            };
        };
        .header-right {
            flex: 50% 1;
            position:relative;
            background: url(../images/lawyers.jpg)center center no-repeat;
            background-size: cover;
            order: 2;
            @media screen and (max-width: $md) {
                order: 1;
                display: flex;
                flex-direction: column;
                .header-icon-play {
                    align-items: flex-end;
                    .icon-play {
                        width: 50px;
                        height: 50px;
                        svg {
                            width: 0.675em;
                        };
                    };
                };
            };
            &:after {
                content: "";
                position: absolute;
                background: rgba(0,0,0,.4);
                top:0;
                left: 0;
                right: 0;
                bottom: 0;
            };
        };
    };
    &.private {
        height: 70px;
        z-index: 10;
        .navbar-custom {
            background: $theme-palette-dark-main;
        };
    };
        
    .header-item-wrapper {
        display:flex;
        flex: 2 1;
        align-items: flex-end;
        z-index: 2;
        position: relative;
        justify-content: center;
    };

    .nav-wrapper {
        margin: 0;

    };
};
.search-wrapper-header {
    flex: 1 1;
    padding: 0 $theme-spacing-unit;
}
.item-link-button {
    padding: 0.5rem $theme-spacing-unit * 3!important;
    border: 0;
    &.sigin {
        background: $theme-palette-dark-main;
        margin-right: $theme-spacing-unit;
        color: $theme-palette-primary-contrastText;
        
        &:hover {
            background: #1b1b1b
        };
    };
    &.register {
        background: $theme-palette-primary-main;
        color: $theme-palette-primary-contrastText;
        &:hover {
            background: $theme-palette-primary-dark;
        };
    };
;}
.navbar-custom {
    position: fixed;
    width: 100%;
    height: 70px;
    z-index: 3;
    color: $theme-palette-primary-contrastText;
    transition: $theme-transition;
    padding: 0 50px;
    @media screen and (max-width: $md) {
        padding: 0 20px;
    };
    &.sticky-header{
        background: rgba(0,0,0,0.69);
    };
    .nav-menu-left,.navbar-collapse {
        flex: 1 1;
        &.grow-left {
            flex: auto 0;
        };
    };
    .nav-menu-left {
        display: flex;
        .menu-left {
            .nav-item {
                .nav-link {
                    height: 100%;
                    &:hover {
                        text-decoration: underline;
                    };
                };
            };
        };
    };
    .navbar-brand, .nav-link {
        color: inherit;
    };
        .nav-link {
        display: flex;
        align-items: center;
    };
};

.nav-wrapper-stacked {
    width: 100%;
    height: auto;
    border: 1px solid $theme-palette-grey-medium;
    .nav-stacked-title {
        background: $theme-palette-grey-light;
        height: 60px;
        display: flex;
        align-items: center;
        padding: 0 $theme-spacing-unit * 2
    };
    .nav {
        .nav-item {
            height: 50px;
            display:flex;
            &:hover {
                background: $theme-palette-grey-medium;
                cursor: pointer;
            };
            .nav-link {
                display:flex;
                padding-left: $theme-spacing-unit * 4;
                align-items: center;
                width: 100%;
                height: 100%;
                color: $theme-palette-grey-main;
            };

            .active {
              background: $theme-palette-primary-main;
              color: $theme-palette-primary-contrastText;
            }
        };
    };
    &.nav-filter {
        .nav-stacked-title {
            background: transparent;
            color: rgba(0, 0, 0, 0.87);
            font-weight: bolder;
        };
        .nav{
            .nav-item {
                .nav-link {
                    color: #637282;
                    div {
                        flex: 1 1;
                        &:first-child {
                            flex: 40px 0;
                        };
                        &:last-child {
                            flex: auto 0;
                        };
                    };
                    &.active {
                        color:#F25037;
                        background: rgba(0,0,0,0.04);
                        font-weight: bolder;
                    };
                };
            };
        };
    };
};


//Navs Tabs
.nav-tabs {
    margin-bottom: $theme-spacing-unit*4;
    font-size: 1.25em;
    .nav-link {
      &:hover {
        cursor: pointer;
      };
    };
};

.header-item-slogan-wrap {
    margin: $theme-spacing-unit*2 0px;
    @media screen and (max-width: $md) {
        margin: 0;
    };
    .item-slogan{
        h1 {
            font-weight: 600;
            font-size: 2.6rem;
        }
        @media screen and (max-width: $md) {
            h1 {
                text-align: center;
                font-weight: 600;
            };
        };
    };
};

.hero-search-wrapper {
    z-index: 100;
}


.item-foot-wrap {
    display: flex;
    align-items: center;
    margin: $theme-spacing-unit*2 0px;
    flex-wrap: wrap;
    @media screen and (max-width: $md) {
        justify-content: center;
    };
    .item-foot {
        flex: auto 0;
        display: flex;
        align-items: center;
        margin-right: $theme-spacing-unit;
        h6 {
            padding-left: $theme-spacing-unit;
        };
    };
};


.dropdown-item.active, .dropdown-item:active {
    background: $theme-palette-primary-main;
    color: $theme-palette-primary-contrastText;
}

.phone-wrapper-break {
    width: 100%;
    padding-top: 80px;
    height: 100%;
    position: relative;
    z-index:1;
    text-align:center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: $md) {
        padding: 0 $theme-spacing-unit;
    };
    h1 {
        font-size: 1.8rem;
    };
    .login-wrapper-break {
        padding: $theme-spacing-unit;
        display: flex;
        justify-content: center;
    }
};

.header-icon-play {
    height: 100%;
    width: 100%;
    background: transparent;
    display:flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    .icon-play {
        background: white;
        border:0;
        border-radius: 50%;
        width: 80px;
        height: 80px;
        display:flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        &:focus {
            outline: 0;
        };
    };
    .icon-toggle {
        position: absolute;
        right: 20px;
        bottom: 20px;
        color: white;
        background: transparent;
        border: 0;
        &:focus {
            outline: 0;
        };
    };
};

.nav-tabs {
    border: 0;
    .nav-item {
        cursor: default;
    };
    &:hover {
        color:inherit;
    };
    .nav-link.active {
        background: transparent;
        border: 0;
        cursor: default;
        font-weight: bold;
    };
};