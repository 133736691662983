@import './vars.scss';
@import './latofonts.css';

.text-danger {
    color: $theme-palette-primary-main!important
};
.text-secondary {
    color: $theme-palette-dark-main
};
.text-primary {
    color: #000!important;
};
.error-text {
    h1 {
        font-size: 8rem;
    };
};

.copyright-link {
    color: $theme-palette-grey-light;
    text-decoration: underline!important;

    &:hover {
        color: $theme-palette-primary-main!important;
    }
}