@import './vars.scss';

.account-unsubscribe {
    max-width: 460px;
    width: calc(100% - 40px);
    padding: 44px;
    margin: 0 auto;
    background: $theme-palette-primary-contrastText;
    position: relative;
    z-index: 1;

    .account-unsunscribe-content {
        margin-top: $theme-spacing-unit *2;

        .buttons-row {
            margin-top: $theme-spacing-unit * 2;
            width: 100%;
            display:flex;
            // padding: 16px;
            button {
              flex: 1 1;
              margin: 0;
              height: 50px;
              &:first-child {
                margin-right: 8px;
              }
              &:last-child {
                margin-left: 8px;
                &:hover {
                  border-color:$theme-palette-grey-muted;
                  color: $theme-palette-dark-contrastText;
                }
              }
            }
        }
    }
}

