@import './vars.scss';
.hero-search {
    width:100%;
    display:flex;
    flex-direction: column;
    transition: $theme-transition;
    @media screen and (max-width: $md) {
        &:not(.hero-search-home) {
            background: $theme-palette-primary-contrastText!important;
            color: $theme-palette-dark-main;
        };
        .hero-form-wrapper {
            form {
                .form-group {
                    .form-control {
                        background: inherit!important;
                        color: $theme-palette-dark-main!important;
                        &::placeholder {
                            color: $theme-palette-dark-main!important;
                        }
                    };
                };
            };
        };
        .btn-dark {
            background: inherit!important;
            color: inherit!important;
            border: 0;
        };
        &.active-search {
            position: fixed;
            left: 0;
            right: 0;
            height: 70px!important;
            top:0;
            transform: translateY(0px);
            z-index: 10;
            box-shadow: 2px 2px 15px rgba(0,0,0,0.5);
        };
        &.inactive-search {
            position: fixed;
            left: 0;
            right: 0;
            height: 70px!important;
            top:0;
            transform: translateY(-70px);
            z-index: 10;
        };
    };
    &.hero-search-fixed {
        position: fixed;
        top: 70px;
        z-index: 1;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.69);
    };
    &.compact-mode {
        height: 36px;
        .hero-form-wrapper{
            form {
                .form-group {
                    .form-control {
                        font-size: 14px!important;
                        &::placeholder {
                            font-size: 14px!important;
                        };
                    };
                };
                .btn-danger {
                    background: white;
                    color: black;
                    border-color: white;
                    font-size: 14px;
                };
            };
        };
        &.dark {
            background: $theme-palette-dark-light;
            border-radius: $theme-radius;
            .hero-form-wrapper {
                background: inherit;
                border-color: $theme-palette-dark-light;
                form {
                    .form-group {
                        .form-control {
                            background: $theme-palette-dark-light;
                            color: $theme-palette-primary-contrastText;
                            &::placeholder {
                                color: $theme-palette-primary-contrastText;
                            };
                        };
                    };
                    .btn-dark {
                        background: $theme-palette-dark-light;
                        border-color: $theme-palette-dark-light;
                        font-size: 14px;
                    };
                };
            };
        };
        .hero-form-wrapper {
            form {
                .form-group {
                    padding-right: 50px;
                    .form-control {
                        font-size: 18px;
                        &::placeholder {
                            font-size: 18px;
                        };
                    };
                };
                .btn {
                    flex: 50px 0;
                    padding: 0;
                };
            };
        };
    };
    &.hero-mode {
        height: 125px;
        @media screen and (max-width: $md) {
            height: 100px;
            z-index: 2;
        };
        .hero-form-wrapper {
            form {
                .form-group {
                    padding-right: 100px;
                    .form-control {
                        font-size: 24px;
                        @media screen and (max-width: $md) {
                            font-size: 18px;
                        };
                        &::placeholder {
                            font-size: 18px;
                        };
                    };
                };
                .btn {
                    flex: 200px 0;
                    @media screen and (max-width: $md) {
                        flex: auto 0;
                        span {
                            display:none;
                        };
                        svg {
                            margin-left: 0;
                        };
                    };
                };
            };
        };
    };
    .hero-form-wrapper {
        flex: 1 1;
        background: $theme-palette-primary-contrastText;
        display:flex;
        form {
            width: 100%;
            display: flex;
            border-radius: $theme-radius;
            .form-group {
                flex: 1 1;
                margin: 0;
                position: relative;
                .form-control {
                    height: 100%;
                    border: 0;
                    color: $theme-palette-dark-main;
                    border-radius: 0;
                    padding-left: $theme-spacing-unit * 4;
                    position:relative;
                    &::placeholder {
                        color: $theme-palette-dark-main;
                    };
                    &:focus {
                        box-shadow: none;
                    };
                };
            };
            .loading-icon {
                position: absolute;
                right: 0;
                bottom: 0;
                top: 0;
                width:100px;
                height: 100%;
            };
            .btn {
                border-radius: 0;
                height: 100%;
                font-size: 24px;
                .button-position {
                    margin-left: $theme-spacing-unit;
                    margin-top: 3px;
                };
            };
        };
    };
    .hero-help {
        flex: 50px 0;
        display: flex;
        justify-content: flex-end;
        @media screen and (max-width: $md) {
            justify-content: center;
        }
        .btn-link {
            color: $theme-palette-primary-contrastText;
            text-decoration: underline;
            background: transparent;
            padding: 0;
        };
    };
};

.search-result {
    background: $theme-palette-primary-contrastText;
    height: auto;
    border-bottom-left-radius: $theme-radius; 
    border-bottom-right-radius: $theme-radius; 
    position: absolute;
    left: 0;
    right: 0;
    color: $theme-palette-dark-main;
    max-height: 400px;
    overflow-y: auto;
    margin-top: -2px;
    border: 1px solid $theme-palette-primary-contrastText;
};
.search-result-item {
    display:flex;
    flex-direction: column;
    padding: $theme-spacing-unit $theme-spacing-unit * 4 $theme-spacing-unit $theme-spacing-unit * 4;
    margin-bottom: $theme-spacing-unit;
    cursor: pointer;
    transition: $theme-transition;
    &:hover {
        background: $theme-palette-grey-light;
    };
    .search-result-item-title {
        margin-bottom: $theme-spacing-unit / 2;
    };
    &.more {
        padding: 0;
        &:hover {
            background: transparent;
        };
        .btn {
            flex: 80px 0!important;
            font-size: 14px!important;
        };
    };
};

