@import './vars.scss';
.login-wrapper {
    padding: 0 $theme-spacing-unit * 8;
    @media screen and (max-width: $md) {
        padding: 0;
    };
    .login-title {
        margin-bottom: $theme-spacing-unit * 2;
    };
    .helper-text-register {
        padding: $theme-spacing-unit 0;
        display: flex;
        justify-content: center;
        align-items: center;
    };
};

.alert {
    padding: $theme-spacing-unit * 2;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    .alert-message {
        flex: auto;
        display: flex;
        align-items: center;
        text-align: center;
    };
}

.login-social-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    .login-social-item {
        flex: 60px 0;
        margin-right: $theme-spacing-unit * 2;
        height: 60px;
        padding: $theme-spacing-unit;
        transition: $theme-transition;
        cursor: pointer;
        &:hover {
            padding: 0;
        };
        img {
            width: 100%;
            height: auto;
            object-fit: cover;
        };
        &:last-child {
            margin-right: 0;
        };
    };
};

.account-recover-wrapper {
    max-width: 460px;
    width: calc(100% - 40px);
    padding: 44px;
    margin: 0 auto;
    background: $theme-palette-primary-contrastText;
    position: relative;
    z-index: 1;
}
.account-recover-logo {
    .logo-brand {
        p {
            margin-left: 8px;
            font-size: 1.25rem;
        };
    };
    a.btn {
      margin-top: 8px;
    }
};
.account-recover-area {
    width: 100%;
    height: auto;
    padding: 20px 0px;
}
