@import './vars.scss';
.comment-item{
    border: 0;
    display: flex;
    margin-bottom: $theme-spacing-unit * 2;
    width: 100%;
    .comment-avatar {
        flex: 80px 0;
        justify-content: center;
        display: flex;
        padding-right: $theme-spacing-unit;
    }
    .comment-content-right{
        margin-right: 80px;
    }
    .comment-content-left{
        margin-left: 80px;
    }
    .comment-text{
        position: relative;
        padding: $theme-spacing-unit * 2;
        .comment-text-footer{
            position: absolute;
            right: $theme-spacing-unit;
            padding: $theme-spacing-unit;
            bottom: 0px;
        }
    }
    .comment-content{
        flex: 1 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: 1px solid $theme-palette-grey-light;
        border-radius: $theme-radius;
        word-break: break-all;
        background: $theme-palette-primary-contrastText;
        & div:first-child {
            display: flex;
            align-items: flex-start;
            flex: auto 0;
            color: $theme-palette-grey-main;
            & strong{
                color: $theme-palette-dark-main;
            }
            & p{
                margin-top: $theme-radius;
                margin-left: $theme-radius;
            }
        }
        & div {
            flex: 1 1;
            display: flex;
            align-items: center;
            padding: $theme-spacing-unit * 2;
            & p{
                margin: $theme-spacing-unit;
            }
        }
    }
}
