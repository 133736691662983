@import './vars.scss';
.stepper-wrapper {
    display: flex;
    flex-direction: column;
    @media screen and (max-width: $md) {
        flex: 1 1;
    };
    .stepper-wraper-icons {
        flex: 80px 0;
        display: flex;
        position: relative;
        .stepper-block {
            display: flex;
            flex: 1 1;
            border-bottom: 1px solid $theme-palette-grey-muted;
            position: relative;
            color: $theme-palette-grey-muted;
            &:last-child {
                @media screen and (max-width: $md) {
                    border: 0;
                    flex: 20px 0;
                };    
            };
            .stepper-number, .stepper-text {
                display: flex;
                align-items: center;
                @media screen and (max-width: $md) {
                    display: none;
                };
            };
            .stepper-number {
                flex: 50px 0;
            };
            .stepper-text {
                flex: 1 1;
                small {
                    min-width: 65px;
                    margin-left: 10px;
                }
            };
            .stepper-circle {
                background: $theme-palette-grey-muted;
                width: 20px;
                height: 20px;
                position: absolute;
                left: 0;
                bottom: -10px;
                border-radius: $theme-radius;
                display:flex;
                align-items: center;
                justify-content: center;
                font-size: 8px;
                color: $theme-palette-primary-contrastText;
            };
            &.active {
                border-color:$theme-palette-bg-avaliable;
                .stepper-number {
                    color: $theme-palette-primary-contrastText;
                };
                .stepper-circle {
                    background: $theme-palette-bg-avaliable;
                }
            };
        };
    };
    .stepper-wraper-info {
        padding: $theme-spacing-unit 0;
        flex: 1 1;
        margin: $theme-spacing-unit 0;
        color: $theme-palette-dark-main;
        max-width: 400px;
        p {
            color: $theme-palette-primary-contrastText;
        }
    };
};
